import React from 'react';

const getInitials = (name) => {
  if (!name) return '';
  const [firstName, lastName] = name.split(' ');
  return `${firstName?.[0] || ''}${lastName?.[0] || ''}`.toUpperCase();
};

const Leaderboard = ({ people }) => {
  // Sort the people array by RVI score in descending order
  const sortedPeople = [...people].sort((a, b) => b.rvi - a.rvi);
  const formatToDollar = (value) => new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  }).format(value);

  return (
    <ul role="list" className="divide-y divide-gray-100">
      {sortedPeople.map((person) => (
        <li key={person.email} className="flex justify-between gap-x-6 py-5">
          <div className="flex min-w-0 gap-x-4">
            {person.profilePicture ? (
              <img
                alt={`${person.name}'s profile`}
                src={person.profilePicture}
                className="h-12 w-12 flex-none rounded-full bg-gray-50"
              />
            ) : (
              <div className="h-12 w-12 flex-none rounded-full bg-gray-200 flex items-center justify-center">
                <span className="text-sm text-gray-500">{getInitials(person.name)}</span>
              </div>
            )}
            <div className="min-w-0 flex-auto">
              <p className="text-sm font-semibold leading-6 text-gray-900">{person.name}</p>
              <p className="mt-1 truncate text-xs leading-5 text-gray-500">{person.email}</p>
            </div>
          </div>
          <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
            <p className="text-sm leading-6 text-gray-900">{formatToDollar(person.rvi)}</p>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default Leaderboard;
