import { types, Instance, flow, SnapshotIn } from "mobx-state-tree"
import { getUserRecordingInfo, getVideo, getModuleAttemptsInfo } from "../../api/routes"

const TranscriptObjectFieldsModel = types
  .model("TranscriptObjectFields", {
    text: types.string,
    user: types.string,
    start: types.string,
    end: types.string,
  })

const RecordingFieldsModel = types
  .model("RecordingFields", {
    user_id: types.string,
    conversation_id: types.string,
    aai_transcript_id: types.maybeNull(types.string),
    activity_id: types.string,
    audioID: types.maybeNull(types.string),
    callType: types.string,
    comments: types.maybeNull(types.string),
    conversation_time: types.maybeNull(types.number),
    conversation_time_str: types.maybeNull(types.string),
    convo_title: types.maybe(types.string),
    createdAt: types.maybeNull(types.number),
    deleted: types.string,
    email: types.string,
    failed_reprocessing: types.maybeNull(types.string),
    fully_processed: types.string,
    name: types.string,
    passed: types.maybeNull(types.string),
    reprocess: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    video_id: types.string,
    transcript: types.array(TranscriptObjectFieldsModel),
    tl_ratio_metric: types.maybeNull(types.string),
    tl_ratio_data: types.maybeNull(types.string),
    pace_metric: types.maybeNull(types.string),
    monologue_metric: types.maybeNull(types.number),
    monologue: types.maybeNull(types.string),
    grading_report: types.maybeNull(types.string),
    filler_metric: types.maybeNull(types.string),
    rubric_score: types.maybeNull(types.string),
    video_url: types.maybeNull(types.string)
  })

const RecordingListFieldsModel = types
  .model("RecordingListFields", {
    recordingList: types.array(RecordingFieldsModel)
  })

export type RecordingListFieldsSnapshotIn = SnapshotIn<typeof RecordingListFieldsModel>
export type RecordingFieldsSnapshotIn = SnapshotIn<typeof RecordingFieldsModel>


export const RecordingListStoreModel = types
  .model("RecordingsStore", {
    userRecordings: types.maybeNull(RecordingListFieldsModel),
  })
  .actions(self => ({
    getUserRecordings: flow(function* getUserRecordings(activityId, initial) {
      try {
        const recordingInfoRes = yield getUserRecordingInfo(activityId)
        const recordingVideoInfo = recordingInfoRes.data.map(record => {
          return { user_id: record.user_id, conversation_id: record.conversation_id, video_id: record.video_id }
        })
        const videoRes = yield getVideo(JSON.stringify(recordingVideoInfo))
        const recordingsWithVideo = recordingInfoRes.data.map(rep => {
          rep['video_url'] = videoRes.data[rep.conversation_id]
          return rep
        })
        if (recordingInfoRes.kind === "ok") {
          if (initial) {
            self.userRecordings = RecordingListFieldsModel.create({
              recordingList: recordingsWithVideo.map(recording => RecordingFieldsModel.create(recording))
            })
          } else {
            self.userRecordings.recordingList.concat(recordingsWithVideo.map(recording => RecordingFieldsModel.create(recording)))
          }
          return true
        }
      } catch (e) {
        console.log('ERROR', e)
        return false
      }
      console.log('ERROR 2')
      return false
    }),
    getModuleAttempts: flow(function* getModuleAttempts(activityId, initial) {
      try {

        const recordingInfoRes = yield getModuleAttemptsInfo(activityId)
        const recordingVideoInfo = recordingInfoRes.data.map(record => {
          return { user_id: record.user_id, conversation_id: record.conversation_id, video_id: record.video_id }
        })
        const videoRes = yield getVideo(JSON.stringify(recordingVideoInfo))
        const recordingsWithVideo = recordingInfoRes.data.map(rep => {
          rep['video_url'] = videoRes.data[rep.conversation_id]
          return rep
        })
        if (recordingInfoRes.kind === "ok") {
          if (initial) {
            self.userRecordings = RecordingListFieldsModel.create({
              recordingList: recordingsWithVideo.map(recording => RecordingFieldsModel.create(recording))
            })
          } else {
            self.userRecordings.recordingList.concat(recordingsWithVideo.map(recording => RecordingFieldsModel.create(recording)))
          }
          return true
        }
      } catch (e) {
        console.log('ERROR', e)
        return false
      }
      console.log('ERROR 2')
      return false
    }),

    getAttempts(activityId: string, userId: string) {
      if (!self.userRecordings) return []
      // Filter the recordings that match the activityId 
      return self.userRecordings.recordingList.filter(recording => recording.activity_id === activityId && recording.user_id == userId)
    },
    addAttempt(attempt) {
      const newAttempt = RecordingFieldsModel.create(attempt)
      self.userRecordings.recordingList.push(newAttempt)
    },
    //for future probably
    removeAttempt(convoId) {
      const index = self.userRecordings.recordingList.findIndex(rep => rep.conversation_id === convoId)
      if (index > -1) {
        self.userRecordings.recordingList.splice(index, 1)
      }
    },
    getSingleAttempt(conversationId: string) {
      if (!self.userRecordings) return []

      // Find the recording that matches the conversationId
      return self.userRecordings.recordingList.find(recording => recording.conversation_id === conversationId) || {}
    },
    isAttemptFetched(activityId: string) {
      if (!self.userRecordings) return false

      return self.userRecordings.recordingList.find(recording => recording.activity_id === activityId) || false
    },
  }))


export type RecordingListStore = Instance<typeof RecordingListStoreModel>


