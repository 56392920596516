import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';

// Scatter Plot Skeleton
export const ScatterPlotSkeleton = () => (
  <div className="bg-white p-4 shadow rounded-lg">
    <Skeleton height={300} />
  </div>
);

// Bar Chart Skeleton
export const BarChartSkeleton = () => (
  <div className="bg-white p-4 shadow rounded-lg">
    <Skeleton height={300} />
  </div>
);

// Rep Velocity Chart Skeleton
export const RepVelocityChartSkeleton = () => (
  <div className="bg-white p-4 shadow rounded-lg">
    <Skeleton height={150} />
  </div>
);

// Mini Graph Skeleton
export const MiniGraphSkeleton = () => (
  <div className="bg-white p-4 shadow rounded-lg">
    <Skeleton height={100} />
  </div>
);

// Breadcrumb Skeleton
export const BreadcrumbSkeleton = () => (
  <div className="mb-8">
    <Skeleton height={40} width={200} />
  </div>
);

// User Greeting Skeleton
export const UserGreetingSkeleton = () => (
  <div className="sm:flex sm:items-center mb-8">
    <div className="sm:flex-auto">
      <Skeleton height={30} width={250} />
      <Skeleton height={20} width={300} style={{ marginTop: '10px' }} />
    </div>
  </div>
);

// Leaderboard Skeleton
export const LeaderboardSkeleton = () => (
  <div className="bg-white p-4 shadow rounded-lg">
    <Skeleton height={150} />
  </div>
);

export const HomePageSkeleton = () => (
  <div className="w-full h-full p-8">
    {/* Breadcrumb Skeleton */}
    <BreadcrumbSkeleton />

    {/* User Greeting Skeleton */}
    <UserGreetingSkeleton />

    {/* Grid container */}
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
      {/* Left column (2/3 width) */}
      <div className="lg:col-span-2 space-y-4">
        {/* Scatter Plot Skeleton */}
        <ScatterPlotSkeleton />

        {/* Team Competency Bar Chart Skeleton */}
        <BarChartSkeleton />
      </div>

      {/* Right column (1/3 width) */}
      <div className="lg:col-span-1 space-y-4">
        {/* Rep Velocity Chart Skeleton */}
        <RepVelocityChartSkeleton />

        {/* Mini charts grid skeletons */}
        <div className="grid grid-cols-2 gap-4 lg:grid-cols-2 sm:grid-cols-1">
          {[...Array(4)].map((_, index) => (
            <MiniGraphSkeleton key={index} />
          ))}
        </div>

        {/* Leaderboard Skeleton */}
        <LeaderboardSkeleton />
      </div>
    </div>
  </div>
);

export default HomePageSkeleton
