import React, { useEffect, useState } from "react";
import { Auth, API } from 'aws-amplify';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
} from "../../@shadcn_components/ui/breadcrumb";
import RadarChart from "../../components/LearningCenter/radarChart";
import BarChart from "../../components/LearningCenter/barChart";
import DropdownSelector from "../../components/LearningCenter/dropdown";
import RolePlayCards from "../../components/LearningCenter/rolePlayCards";
import HomePageSkeleton from "../../components/Homedash/skeletons";
import { useStores } from "../../models/root-store";
import { observer } from "mobx-react-lite";

// Dummy data for mapping scores to numbers
const scoreToNumericValue = {
  'Foundational': 1,
  'Proficient': 2,
  'Advanced': 3,
  'Expert': 4,
};

const sampleRolePlays = [
  {
    title: 'Cold Calling Techniques',
    objective: 'Mastering the opening, delivering value propositions, handling rejection, and securing next steps.',
  },
  {
    title: 'Elevator Pitch',
    objective: 'Delivering concise, relevant pitches, capturing interest, and ending with a strong call to action.',
  },
  {
    title: 'Handling Gatekeepers',
    objective: 'Effective communication with gatekeepers to reach decision makers.',
  },
];

const LearningCenterPage = () => {
  const { userStore } = useStores();
  const userEmail = userStore.user.userEmail;

  const [isLoading, setIsLoading] = useState(true);
  const [profiles, setProfiles] = useState([]);  // State to store profiles dynamically
  const [userEmails, setUserEmails] = useState([]); 
  const [selectedUserEmail, setSelectedUserEmail] = useState("");
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [radarChartLabels, setRadarChartLabels] = useState([]); // Labels for Radar chart
  const [selectedUserRadarData, setRadarChartData] = useState([]); // Data for Radar chart
  const [radarTopPerformerData, setRadarTopPerformerData] = useState([]); // Baseline data for Radar chart
  const [barChartLabels, setBarChartLabels] = useState([]); // Labels for Bar chart
  const [barChartData, setBarChartData] = useState([]); // Data for Bar chart
  const [barTopPerformerData, setBarTopPerformerData] = useState([]); // Baseline data for Bar chart
  const [rolePlays, setRolePlays] = useState([]);
  const [subCompetencyOptions, setSubCompetencyOptions] = useState([]);
  const [selectedSubCompetency, setSelectedSubCompetency] = useState("");

  const isAdminOrManager = userStore.user.type === "Admin" || userStore.user.type === "Manager";

  useEffect(() => {
    const fetchCompetencyProfile = async () => {
      try {
        const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
  
        if (isAdminOrManager) {
          // Fetch profiles for managers/admins
          const profiles = await API.get(
            'DataIntegration',
            `/competencyProfileDataByManager?email=${userEmail}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setProfiles(profiles);
          const emails = profiles.map(profile => profile.userEmail);
          setUserEmails(emails);
          setSelectedUserEmail(emails[0]);
          setSelectedProfile(profiles[0]);
          initializeCharts(profiles[0]);
  
        } else {
          // Fetch individual user profile for non-admins/managers
          const profile = await API.get(
            'DataIntegration',
            `/competencyProfileData?email=${userEmail}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (profile) {
            setSelectedProfile(profile);
            setSelectedUserEmail(profile.userEmail);
            initializeCharts(profile);
          }
        }
  
        setRolePlays(sampleRolePlays);
      } catch (error) {
        console.error("Error fetching competency profiles:", error);
      } finally {
        setIsLoading(false)
      }
    };
  
    fetchCompetencyProfile();
  }, [userEmail, isAdminOrManager]);
  

  const initializeCharts = (selectedProfile) => {
    // Log the selected profile for debugging
    console.log(`Using profile for user: ${selectedProfile.userEmail}`);

    // Extract competency labels and scores for RadarChart
    const radarLabels = Object.keys(selectedProfile.competencyScores);
    const selectedUserRadarData = Object.values(selectedProfile.competencyScores);

    // Assuming top performer data is coming from an external source or baseline calculation
    const topPerformerRadarData = radarLabels.map(() => 8); // Example: top performers score "8"

    setRadarChartLabels(radarLabels);
    setRadarChartData(selectedUserRadarData);
    setRadarTopPerformerData(topPerformerRadarData);

    // Set sub-competency options for the bar chart dropdown (based on first competency category)
    const initialSubCompetency = radarLabels[0];
    const subCompetencies = Object.keys(selectedProfile.competencyProfile[initialSubCompetency]);
    const subCompetencyValues = subCompetencies.map(sub => scoreToNumericValue[selectedProfile.competencyProfile[initialSubCompetency][sub]]);

    // Assuming top performer data is the same for bar chart, you can customize it
    const topPerformerBarData = subCompetencies.map(() => 4); // Example: top performers score "Expert"

    setSubCompetencyOptions(radarLabels);
    setSelectedSubCompetency(initialSubCompetency);
    setBarChartLabels(subCompetencies);
    setBarChartData(subCompetencyValues);
    setBarTopPerformerData(topPerformerBarData);
  };

  const handleSubCompetencyChange = (e) => {
    const newSubCompetency = e.target.value;
    setSelectedSubCompetency(newSubCompetency);
  
    // Use the profile from state directly (whether it's from multiple profiles or an individual profile)
    const selectedProfileFromState = isAdminOrManager
      ? profiles.find(profile => profile.userEmail === selectedUserEmail)
      : selectedProfile;  // Directly use the selectedProfile from state for individual users
  
    if (selectedProfileFromState) {
      const subCompetencies = Object.keys(selectedProfileFromState.competencyProfile[newSubCompetency]);
      const subCompetencyValues = subCompetencies.map(
        (sub) => scoreToNumericValue[selectedProfileFromState.competencyProfile[newSubCompetency][sub]]
      );
  
      setBarChartLabels(subCompetencies);
      setBarChartData(subCompetencyValues);
    }
  };
  
  

  const handleUserChange = (e) => {
    const selectedEmail = e.target.value;
    setSelectedUserEmail(selectedEmail);

    // Find and set the corresponding profile for the selected user
    const profile = profiles.find((profile) => profile.userEmail === selectedEmail);
    if (profile) {
      initializeCharts(profile); // Update charts based on the selected user
    }
  };

  if (isLoading) {
    return (
      <HomePageSkeleton/> // build custom skeletons? maybe later. hp skeleton looks good
    );
  }

  return (
    <div className="w-full h-full flex flex-col px-8 md:px-20 pt-8">
      <div className="mb-8">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>{isAdminOrManager ? "Coaching" : "Learning"}</BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>

      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {isAdminOrManager ? "Coaching Center" : "Learning Center"}
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            {isAdminOrManager 
              ? "Track your reps' competency performance and help them hone their skills"
              : "See how you stack up against the Ideal Rep Profile"
            }
          </p>
        </div>

        {isAdminOrManager && (
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <DropdownSelector
              label="Select User"
              options={userEmails}
              value={selectedUserEmail}
              onChange={handleUserChange}
            />
          </div>
        )}
      </div>

      <div className="mt-8 flex justify-center">
        <div className="w-full p-4 bg-white shadow-lg rounded-md">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Radar Chart Section with Title */}
            <div className="w-full p-4 border border-gray-300 rounded-md">
              <h2 className="text-md md:text-lg font-semibold leading-6 text-gray-900 mb-4">
                Top Performer Resemblance
              </h2>
              <RadarChart
                labels={radarChartLabels}
                selectedUserEmail={selectedUserEmail}
                selectedUserData={selectedUserRadarData}
                topPerformerData={radarTopPerformerData}
              />
            </div>

            {/* Bar Chart Section with Dropdown and Title */}
            <div className="w-full p-4 border border-gray-300 rounded-md">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-md md:text-lg font-semibold leading-6 text-gray-900 mb-4">
                  Sub-Competencies
                </h2>
                {/* Hide dropdown on smaller screens, display it below the title */}
                <div className="">
                  <DropdownSelector
                    label=""
                    options={subCompetencyOptions} // Pass the list of options
                    value={selectedSubCompetency}
                    onChange={handleSubCompetencyChange}
                  />
                </div>
              </div>
              <BarChart
                labels={barChartLabels}
                data={barChartData}
                topPerformerData={barTopPerformerData}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Role Play Cards Section */}
      <RolePlayCards rolePlays={rolePlays} />
    </div>
  );
};

export default observer(LearningCenterPage);
