import React, { useState, useEffect } from "react"
import { Auth, API } from "aws-amplify"
import WarningPopUp from "../../components/PopUps/WarningPopUp"
import InviteUserPopUp from "../../components/PopUps/InviteUserPopUp"
import LoadingPopUp from "../../components/PopUps/LoadingPopUp"
import TeamTable from "../../components/TeamPage/teamTable"
import "react-multi-email/dist/style.css"
import "./MyTeamPage.css"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"
import { toJS } from "mobx";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../@shadcn_components/ui/breadcrumb"
import TeamPageSkeleton from "../../components/TeamPage/skeletons"



const MyTeamPage = () => {
  const { userStore, teamStore } = useStores()
  const teamMemberList = toJS(teamStore.teamInfo ?.team) || []
  const inviteeList = toJS(teamStore.teamInfo ?.inviteeList) || []
  const [showInviteUserPopUp, setShowInviteUserPopUp] = useState(false)
  const [showDeletePopUp, setShowDeletePopUp] = useState(false)
  const [deleteDetails, setDeleteDetails] = useState({})
  const [popUpLoading, setPopUpLoading] = useState(false)
  const [emails, setEmails] = useState([])
  const [focused, setFocused] = useState(false)
  const [type, setType] = useState("")
  const [pageLoading, setPageLoading] = useState(true)

  useEffect(() => {
    const fetchTeam = async () => {
      await teamStore.getTeam(userStore.user.userId, userStore.user.type, userStore.user.company)
      setPageLoading(false)
    }

    if (!teamStore.teamInfo) {
      fetchTeam()
    } else {
      setPageLoading(false)
    }
  }, [])

  const deleteInvitee = async inviteeEmail => {
    await API.post("ZenoApp", "/deleteInvitee", {
      headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      body: {
        inviteeEmail,
      },
    })
    teamStore.removeInvitedRep(inviteeEmail)
  }
  const openDeletePopUp = teamMemberEntryObj => {
    setDeleteDetails(teamMemberEntryObj)
    setShowDeletePopUp(true)
  }
  const closeDeletePopUp = () => {
    setShowDeletePopUp(false)
    setDeleteDetails(null)
  }

  const deleteUser = async () => {
    setPopUpLoading(true)
    await API.post("ZenoApp", "/deleteUser", {
      headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      body: {
        deleted_user_id: deleteDetails.user_id,
      },
    })
    teamStore.removeSalesRep(deleteDetails.user_id)
    setShowDeletePopUp(false)
    setDeleteDetails(null)
    setPopUpLoading(false)
  }

  const sendInviteToUsers = async () => {
    if (type.length > 0 && emails.length > 0) {
      setPopUpLoading(true)
      await API.post("ZenoApp", "/sendInvites", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        body: {
          manager_name: userStore.user.name,
          managerId: userStore.user.userId,
          emailList: emails.join(","),
          company: userStore.user.company,
          type: type,
        },
      })
      teamStore.addInvitedRep(emails, type)
      setEmails([])
      setType("")
      setPopUpLoading(false)
      setShowInviteUserPopUp(false)
    } else {
      alert("Please fill out all the information")
    }
  }

  const handleInviteUserClick = () => setShowInviteUserPopUp(true)
  const handleTypeChange = event => setType(event.target.value)
  const handleBackButtonClick = () => setShowInviteUserPopUp(false)

  const inviteUserPopUp = showInviteUserPopUp ? (
    <div className={`frostedBackground is-visible`}>
      {popUpLoading ? (
        <LoadingPopUp header="Inviting Reps..."></LoadingPopUp>
      ) : (
          <InviteUserPopUp
            header={"Add Team Member"}
            body={`Please enter the emails of your new team members, click enter when you've written out the whole email`}
            leftButtonLabel="Cancel"
            leftButtonFunction={handleBackButtonClick}
            rightButtonLabel="Send Invite"
            rightButtonFunction={sendInviteToUsers}
            setEmails={setEmails}
            setFocused={setFocused}
            handleTypeChange={handleTypeChange}
            userType={userStore.user.type}></InviteUserPopUp>
        )}
    </div>
  ) : null

  const deleteUserPopUp = showDeletePopUp ? (
    <div className={`frostedBackground is-visible`}>
      {popUpLoading ? (
        <LoadingPopUp header="Deleting Reps..."></LoadingPopUp>
      ) : (
          <WarningPopUp
            header="Remove Team Member"
            body={`Are you sure you would like to remove ${deleteDetails.name}?`}
            leftButtonFunction={closeDeletePopUp}
            leftButtonLabel="Cancel"
            rightButtonFunction={deleteUser}
            rightButtonLabel="Confirm Removal"></WarningPopUp>
        )}
    </div>
  ) : null

  return (
    <>
      {pageLoading ? <TeamPageSkeleton></TeamPageSkeleton> :
        <div className="w-full pt-8 ">
          <div className="mb-8 px-8">
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem>My Team</BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
          </div>
          <div class="px-4 sm:px-6 lg:px-8 w-full">
            <div class="sm:flex sm:items-center">
              <div class="sm:flex-auto">
                <h1 class="text-base font-semibold leading-6 text-gray-900">Sales Team</h1>
                <p class="mt-2 text-sm text-gray-700">A list of your Pare sales org members.</p>
              </div>
              <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                <button
                  type="button"
                  class="block rounded-md bg-pareBlue px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={handleInviteUserClick}>
                  Add user
              </button>
              </div>
            </div>
            <TeamTable
              teamMemberList={teamMemberList}
              inviteeList={inviteeList}
              pageLoading={pageLoading}
              openDeletePopUp={openDeletePopUp}
              deleteInvitee={deleteInvitee}
              currentUserName={userStore.user.name}
            />
          </div>
        </div>}
      {inviteUserPopUp}
      {deleteUserPopUp}
    </>
  )
}

export default observer(MyTeamPage)
