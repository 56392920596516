import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import Messages from "../../components/Messages/Messages"
import { Auth, API } from "aws-amplify"
import { toJS } from "mobx";
import { grid } from "ldrs"
import "./ModuleAnalysisPage.css"
import "video-react/dist/video-react.css"
import { Tabs, TabsList, TabsTrigger } from "../../@shadcn_components/ui/tabs"
import DynamicText from "../../components/TextComponents/DynamicText"
import SubmitReviewPopUp from "../../components/PopUps/SubmitReviewPopUp"
import LoadingPopUp from "../../components/PopUps/LoadingPopUp"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../@shadcn_components/ui/breadcrumb"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"

grid.register()

const ModuleAnalysisPage = () => {
  const { userStore, recordingListStore } = useStores()
  const [transcript, setTranscript] = useState(JSON.parse(location.state.transcript))
  const location = useLocation()
  //const [convoTitle, setConvoTitle] = useState(location.state.activity_id ? location.state.testName : conversationData.convo_title)
  const [totalDuration, setTotalDuration] = useState(location.state.conversation_time_str)
  const [videoURL, setVideoURL] = useState(location.state.video_url)
  const AI_report = location.state.grading_report
  const formatted_AI_report = AI_report.replace(/- /g, "\u2022 ").replace(/^\n/, "").replace(/\*\*(.*)\*\*/gm, "$1").replace(/###(.*\n)/gm, "$1")
  const [aiReport, setAIReport] = useState(formatted_AI_report.replace(/\|\|.*\|\|\n/gm, ""))
  const [viewType, setViewType] = useState("Overview")
  const [metricOpen, setMetricOpen] = useState("PaceMetric")
  const [searchBarInput, setSearchBarInput] = useState("")
  const [talkTrackPos, setTalkTrackPos] = useState(0)
  const [teamMemberName, setTeamMemberName] = useState(location.state.name)
  const [rubricScore, setRubricScore] = useState(location.state.rubric_score.replace(/Overall Score:/g, ""))
  const [showSubmitReviewPopUp, setShowSubmitReviewPopUp] = useState(false)
  const [popUpLoading, setPopUpLoading] = useState(false)
  const [reviewSuccess, setReviewSuccess] = useState(true)
  const [comments, setComments] = useState("")
  const [passed, setPassed] = useState(false)
  const [date, setDate] = useState(new Date(location.state.createdAt).toDateString())

  useEffect(() => {
    const fetchRecordings = async () => {
      if (!recordingListStore.userRecordings) {
        await recordingListStore.getUserRecordings()
      }
    }
    const fetchData = async () => {
      try {
        // Fetch only if necessary
        // await Promise.all([fetchRecordings()]);

        // const conversationData = toJS(recordingListStore.getSingleAttempt(location.state.conversation_id))
        // console.log(conversationData)
        // setTeamMemberName(conversationData.name)
        // const convo_date = new Date(conversationData.createdAt)
        // setDate(convo_date.toDateString())
        // setConvoTitle(
        //   location.state.activity_id ? location.state.testName : conversationData.convo_title,
        // )
        // setTranscript(JSON.parse(conversationData.transcript))
        // const AI_report = conversationData.grading_report
        // const formatted_AI_report = AI_report.replace(/- /g, "\u2022 ").replace(/^\n/, "").replace(/\*\*(.*)\*\*/gm, "$1").replace(/###(.*\n)/gm, "$1")
        // setAIReport(formatted_AI_report.replace(/\|\|.*\|\|\n/gm, ""))
        // const total_duration = conversationData.conversation_time_str
        // setTotalDuration(total_duration)
        // setRubricScore(conversationData.rubric_score.replace(/Overall Score:/g, ""))
        // setVideoURL(conversationData.video_url)
      } catch (error) {

      } finally {

      }
    }
    // fetchData()
  }, [])

  const handleCommentChange = e => {
    setComments(e.target.value)
  }

  const handleSearchBarChange = e => {
    e.preventDefault()
    setSearchBarInput(e.target.value)
  }
  const markAsPassed = async () => {
    setPopUpLoading(true)
    await API.post("ZenoApp", "/markAsPassed", {
      headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      body: {
        activity_id: location.state.activity_id,
        user_id: location.state.user_id,
        conversation_id: location.state.conversation_id,
        passed: reviewSuccess,
        comments: comments,
      },
    })
    setPassed(true)
    setPopUpLoading(false)
    setShowSubmitReviewPopUp(false)
  }
  const handleViewTransition = view => {
    if (view === "Overview") {
      setViewType("Overview")
    } else if (view === "Scoring") {
      setViewType("Scoring")
    } else {
      switchToAnalysisView()
    }
  }

  const switchToAnalysisView = () => {
    setViewType("Analysis")
    setMetricOpen("PaceMetric")
  }

  const handleTimeUpdate = event => {
    setTalkTrackPos(event.target.currentTime)
  }

  const exitPopUp = () => {
    setShowSubmitReviewPopUp(false)
  }

  const submitReviewPopUp = showSubmitReviewPopUp ? (
    <div className={`frostedBackground is-visible`}>
      {popUpLoading ? (
        <LoadingPopUp header="Submitting Feedback..."></LoadingPopUp>
      ) : (
          <SubmitReviewPopUp header="Submit Review" body1="Select whether the rep passed this module or try again" body2="Leave some feedback for your rep on what influenced your decision" rightButtonFunction={markAsPassed} rightButtonLabel="Submit" leftButtonFunction={exitPopUp} leftButtonLabel="Cancel" comments={comments} handleCommentChange={handleCommentChange}></SubmitReviewPopUp>
        )}
    </div>
  ) : null

  const handleSubmitReviewButtonClick = () => {
    setShowSubmitReviewPopUp(true)
  }

  const isManager = userStore.user.type === "Manager"
  const pageName = isManager ? `${teamMemberName}'s Attempt` : "My Attempt"
  const breadCrumbView = (
    <div className="flex flex-col items-start">
      <div className="mb-8">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href={isManager ? "/managerDev" : "/assignmentsPage"}>Modules</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink>{`${location.state.testName}`}</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{pageName}</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <div className="w-full flex flex-row justify-between items-center mb-4">
        <h1 className="text-2xl font-semibold">{location.state.testName}</h1>
        {isManager ? (
          <button
            onClick={handleSubmitReviewButtonClick}
            className="py-2 px-2 md:px-8 rounded-md bg-blue-600 text-white text-sm font-semibold">
            Submit Review
          </button>
        ) : null}
      </div>
    </div>
  )

  const cardsView =
    <div className="cardsContainer mb-4">
      {isManager ? (
        <div className="cardContainer">
          <label>{teamMemberName}</label>
        </div>
      ) : null}
      <div className="cardContainer">
        <div className="blackCalendarIcon"></div>
        <label>{date}</label>
      </div>
      <div className="cardContainer">
        <div className="blackClockIcon"></div>
        <label>{totalDuration}</label>
      </div>
    </div>

  const tabs = (
    <Tabs defaultValue="Overview" className="mb-4" onValueChange={str => handleViewTransition(str)}>
      <TabsList>
        <TabsTrigger value="Overview">Overview</TabsTrigger>
        <TabsTrigger value="Scoring">Scoring</TabsTrigger>
      </TabsList>
    </Tabs>
  )

  const pendingView = (
    <div className="flex-1 flex flex-row content-center justify-center">
      <l-grid size="120" speed="1.4" color="#3045FF" className="mt-50" />
    </div>
  )

  const videoView = (
    <div className="flex-1 aspect-[16/9] 2xl:w-1/2">
      <video src={videoURL} controls height="100%" width="100%" preload="auto" onTimeUpdate={handleTimeUpdate}></video>
    </div>
  )

  const messagesToDisplay = searchBarInput.length
    ? transcript.filter(x => x.text.match(new RegExp(`^.*${searchBarInput}.*`, "i")))
    : transcript

  const transcriptView = (
    <div className="flex-1 flex flex-col aspect-[16/9] 2xl:w-1/2">
      <div className="flex flex-col px-2">
        <p className="text-lg">Transcript</p>
        <input
          type="text"
          placeholder="Search"
          className="w-full rounded-sm border-2 border-gray-400 mb-2"
          value={searchBarInput}
          onChange={handleSearchBarChange}></input>
      </div>
      <div className="flex-1 overflow-auto px-2 pt-2">
        <Messages messages={messagesToDisplay} name={""} />
      </div>
    </div>
  )

  // const heatmapView = (
  //   <div className="hidden xl:block">
  //     <div className="w-full">
  //       <p className="text-xl font-semibold">{`${teamMemberName}`}</p>
  //       <div className="relative h-[2px] bg-gray-500 my-4">
  //         {JSON.parse(data.conversationData.user_transcripts_data.transcript)
  //           .filter(x => x.user === "user")
  //           .map(x => (
  //             <div
  //               key={`user-bar-${x.start}`}
  //               style={{
  //                 width: `${((x.end - x.start) / 1000 / totalDuration) * 100}%`,
  //                 height: "10px",
  //                 background: "#3045ff",
  //                 position: "absolute",
  //                 top: "-4px",
  //                 left: `${(x.start / 1000 / totalDuration) * 100}%`,
  //                 borderRadius: "5px",
  //               }}></div>
  //           ))}
  //         <div
  //           style={{
  //             width: "3px",
  //             height: "20px",
  //             background: "black",
  //             position: "absolute",
  //             top: "-7.5px",
  //             left: `${(talkTrackPos / totalDuration) * 100}%`,
  //           }}></div>
  //       </div>
  //     </div>
  //     <div className="ProspectTalkTrackContainer">
  //       <p className="text-xl font-semibold">{`Prospect`}</p>
  //       <div className="relative h-[2px] bg-gray-500 my-4">
  //         {JSON.parse(data.conversationData.user_transcripts_data.transcript)
  //           .filter(x => x.user === "bot")
  //           .map(x => (
  //             <div
  //               key={`bot-bar-${x.start}`}
  //               style={{
  //                 width: `${((x.end - x.start) / 1000 / totalDuration) * 100}%`,
  //                 height: "10px",
  //                 background: "grey",
  //                 position: "absolute",
  //                 top: "-4px",
  //                 left: `${(x.start / 1000 / totalDuration) * 100}%`,
  //                 borderRadius: "5px",
  //               }}></div>
  //           ))}
  //         <div
  //           style={{
  //             width: "3px",
  //             height: "20px",
  //             background: "black",
  //             position: "absolute",
  //             top: "-7.5px",
  //             left: `${(talkTrackPos / totalDuration) * 100}%`,
  //           }}></div>
  //       </div>
  //     </div>
  //   </div>
  // )

  // "Overview" tab
  const overviewTab = (
    <div className="flex-1 flex flex-col">
      <div className="flex flex-col 2xl:flex-row 2xl:mb-4">
        {videoView}
        {transcriptView}
      </div>
      {/* {heatmapView} */}
    </div>
  )

  // "Scoring" tab
  const scoringTab = (
    <div className="flex flex-col 2xl:flex-row">
      <div className="flex flex-col items-center px-10 pt-12">
        <p className="text-3xl font-bold mb-3">{rubricScore}</p>
        <div className="text-md text-gray-300">Score</div>
      </div>

      <div className="whitespace-pre-line text-lg 2xl: text-xl text-slate-400 pb-10">
        <DynamicText content={aiReport}></DynamicText>
      </div>
    </div>
  )

  return (
    <div className="w-full h-full flex flex-col px-8 2xl:px-20 pt-8">
      {breadCrumbView}
      {cardsView}
      {tabs}
      {viewType === "Overview" ? overviewTab : viewType === "Scoring" ? scoringTab : null}
      {submitReviewPopUp}
    </div>
  )
}

export default observer(ModuleAnalysisPage)
