import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

const statuses = {
  'Complete': 'text-green-700 bg-green-50 ring-green-600/20',
  'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
  'Not started': 'text-red-700 bg-red-50 ring-red-600/20',
};

export default function ChecklistDrawer({ isOpen, setIsOpen, checklistData }) {
  return (
    <Dialog open={isOpen} onClose={setIsOpen} className="relative z-10">
      <div className="fixed inset-0" />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                <div className="bg-pareBlue px-4 py-6 sm:px-6">
                  <div className="flex items-center justify-between">
                    <DialogTitle className="text-base font-semibold leading-6 text-white">Admin Checklist</DialogTitle>
                    <button
                      type="button"
                      onClick={() => setIsOpen(false)}
                      className="relative rounded-md bg-pareBlue text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                    >
                      <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                    </button>
                  </div>
                </div>

                <div className="relative flex-1 px-4 py-6 sm:px-6">
                  <ul role="list" className="divide-y divide-gray-100">
                    {checklistData.map((task) => (
                      <li key={task.id} className="flex items-center justify-between gap-x-6 py-5">
                        {/* Task Name and Status */}
                        <div className="min-w-0 flex-1">
                          <div className="flex items-start gap-x-3">
                            <p className="text-sm font-semibold leading-6 text-gray-900">{task.name}</p>
                            <p
                              className={`${statuses[task.status]} mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset`}
                            >
                              {task.status}
                            </p>
                          </div>
                        </div>

                        {/* Go to task button */}
                        <div className="flex-none">
                          <a
                            href={task.href}
                            className="rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          >
                            Go to task
                          </a>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
