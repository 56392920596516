import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';

const scoreDescriptions = {
  1: {
    label: "Foundational",
    description: "Understands the basic concepts of the competency",
  },
  2: {
    label: "Proficient",
    description: "Able to execute the competency in certain sales situations; typically, simple sales situations",
  },
  3: {
    label: "Advanced",
    description: "Able to consistently execute across a wide range of sales situations, including those with complexity and as a result are experiencing positive outcomes",
  },
  4: {
    label: "Expert",
    description: "Capable of teaching others how to improve their execution of the competency; often seen as an expert on the competency by their peers",
  },
};


export default function SubCompetencyModal({ open, onClose, title, description, score }) {
  const scoreContext = scoreDescriptions[score] || { label: 'N/A', description: 'No score available' };
  return (
    <Dialog open={open} onClose={onClose} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
          >
            <div>
              <div className="mt-3 text-center sm:mt-5">
                <DialogTitle as="h3" className="text-lg font-semibold leading-6 text-gray-900">
                  {title}
                </DialogTitle>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">{description}</p>

                  {/* Display the user's score with context */}
                  <div className="mt-4 text-center">
                    <p className="text-sm font-bold text-gray-700">User Score: {score} – {scoreContext.label}</p>
                    <p className="text-sm text-gray-500 mt-1">{scoreContext.description}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-1 sm:gap-3">
              <button
                type="button"
                onClick={onClose}
                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Close
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
