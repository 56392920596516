// radarChart.jsx
import React, { useMemo } from 'react';
import { Radar } from 'react-chartjs-2';

const RadarChart = ({ labels, selectedUserEmail, selectedUserData, topPerformerData }) => {
  const chartData = useMemo(
    () => ({
      labels: labels,
      datasets: [
        {
          label: selectedUserEmail,
          data: selectedUserData || [1, 1, 1, 1, 1],  // Original user data
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 2,
        },
        {
          label: 'Top Performer',
          data: topPerformerData || [3, 3, 3, 3, 3],  // Sample baseline data
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 2,
        },
      ],
    }),
  );

  const options = useMemo(
    () => ({
      scales: {
        r: {  // 'r' is the radial axis in radar charts
          ticks: {
            stepSize: 1,
          },
          beginAtZero: false,
            min: 4,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
    }),
    []
  );

  return (
    <div className="" style={{ height: '300px', width: '100%' }}>
      <Radar data={chartData} options={options} />
    </div>
  );
};

export default RadarChart;
