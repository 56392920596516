import React, { useEffect, useState } from "react"
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'

import "./ActionsMenuDropdown.css"

const ActionsMenuDropdown = ({ deleteAction, renameAction, style }) => {
  const [openMenu, setOpenMenu] = useState(false)

  const onItemClick = option => {

    if (option === 'delete') {
      deleteAction()
    } else if (option === 'rename') {
      renameAction()
    } else {
    }
  }

  useEffect(() => {
    const handler = () => setOpenMenu(false)

    window.addEventListener("click", handler)
    return () => {
      window.removeEventListener("click", handler)
    }
  }, [])

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-pareBlue focus:ring-offset-2 focus:ring-offset-gray-100" onClick={(event) => event.stopPropagation()}>
          <span className="sr-only">Open options</span>
          <EllipsisVerticalIcon aria-hidden="true" className="size-5" />
        </MenuButton>
      </div>

      <MenuItems
        transition
        className="absolute right-30 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="py-1">
          <MenuItem >
            <div
              onClick={() => onItemClick('rename')}
              href="#"
              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
            >
              Rename
            </div>
          </MenuItem>
          <MenuItem >
            <div
              onClick={() => onItemClick('delete')}
              href="#"
              className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
            >
              Delete
            </div>
          </MenuItem>
        </div>
      </MenuItems>
    </Menu >
    // <div className={"action-dropdown-container"}>
    //   <div className={"action-dropdown-input"} onClick={handleClick}>
    //     <div className="dropdown-tools">
    //       <div className="dropdown-tool">{/* <Icon /> */}</div>
    //     </div>
    //     {openMenu && (
    //       <div className={"action-dropdown-menu"} onMouseOver={e => e.stopPropagation()}>
    //         {options.map((x, i) => (
    //           <div key={x.value} className={"action-dropdown-item"} onClick={() => onItemClick(x)}>
    //             {x.label}
    //           </div>
    //         ))}
    //       </div>
    //     )}
    //   </div>
    // </div>
  )
}

export default ActionsMenuDropdown
