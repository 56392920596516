import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { sendToVercelAnalytics } from "./vitals"
import { Amplify } from "aws-amplify"
import config from "./config"
import { Auth } from "aws-amplify"
import awsmobile from "./aws-exports"

// AWS configs
Auth.configure(awsmobile)
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: "us-east-1",
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: "ZenoApp",
        endpoint: process.env.REACT_APP_API_ENDPOINT,
        region: "us-east-1",
      },
      {
        name: "DataIntegration",
        endpoint: process.env.REACT_APP_DI_ENDPOINT,
        region: "us-east-1",
      },
    ],
  },
})

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
)

reportWebVitals(sendToVercelAnalytics)
