import React, { useEffect, useState } from "react"
import Recordings from "../../components/Recordings/Recordings"
import { useNavigate, useLocation } from "react-router-dom"
import { Auth, API } from "aws-amplify"
import { grid } from "ldrs"
import "./AssignmentLandingPage.css"
import { Tabs, TabsList, TabsTrigger } from "../../@shadcn_components/ui/tabs"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../@shadcn_components/ui/breadcrumb"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"
import { toJS } from "mobx";
import AssignmentLandingPageSkeleton from "../../components/AssignmentLandingPage/skeletons"


grid.register()

const AssignmentLandingPage = () => {
  const { userStore, recordingListStore, moduleListStore } = useStores()
  const [attemptArray, setAttemptArray] = useState([])
  const [pageLoading, setPageLoading] = useState(true)
  const navigate = useNavigate()
  const location = useLocation()
  const [refreshAllowed, setRefreshAllowed] = useState(true)
  const [refreshInProgress, setRefreshInProgress] = useState(false)
  const [deletePopUpMenu, setDeletePopUpMenu] = useState(false)
  const [renamePopUpMenu, setRenamePopUpMenu] = useState(false)
  const [popUpMenuDetails, setPopUpMenuDetails] = useState(null)
  const [loading, setLoading] = useState(false)
  const [selectedTag, setSelectedTab] = useState("Overview")
  const [moduleCatInfo, setModuleCatInfo] = useState({
    description: "",
    objective: "",
    persona_summary: "",
    scenario: "",
    rubric_name: "",
    links: "",
    duration: "",
    dueDate: "",
    numAttempts: "",
    testType: ""
  })
  const [modulePendingReview, setModulePendingReview] = useState("Not Passed")
  const managerActivityData = moduleListStore.getModuleUserSpecific(location.state.activity_id, userStore.user.userId)



  useEffect(() => {

    const fetchRecordings = async () => {
      if (!recordingListStore.userRecordings) {
        await recordingListStore.getUserRecordings(location.state.activity_id, false)
      } else {
        await recordingListStore.getUserRecordings(location.state.activity_id, true)
      }
    }

    const fetchModules = async () => {
      if (!moduleListStore.userModules) {
        if (userStore.user.type === 'SDR' || userStore.user.type === 'AE') {
          await moduleListStore.getAssignedModules(userStore.user.company)
        } else {
          await moduleListStore.getManagerModules(userStore.user.type, userStore.user.company)
        }
      }
    }

    const fetchData = async () => {
      try {
        // Fetch only if necessary
        await Promise.all([fetchRecordings(), fetchModules()]);

        const attempts = toJS(recordingListStore.getAttempts(location.state.activity_id, userStore.user.userId).map(x => toJS(x)))
        setAttemptArray(attempts)
        const dueDate = managerActivityData.dueDate
        setModuleCatInfo({
          description: managerActivityData.description,
          objective: managerActivityData.objective,
          persona_summary: managerActivityData.persona_summary,
          scenario: managerActivityData.scenario,
          rubric_name: managerActivityData.rubricFile,
          links: managerActivityData.links,
          duration: managerActivityData.duration,
          dueDate,
          numAttempts: attempts.length,
          testType: managerActivityData.testType
        })

        const user_status = managerActivityData.userStatus
        setModulePendingReview(user_status)

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setPageLoading(false)
      }
    }
    fetchData()

  }, [])

  const openMenuPopUp = convoObj => {
    setPopUpMenuDetails(convoObj)
    if (convoObj.popUpType === "delete") {
      setDeletePopUpMenu(true)
    } else if (convoObj.popUpType === "rename") {
      setRenamePopUpMenu(true)
    }
  }
  const closeMenuPopUp = () => {
    if (popUpMenuDetails.popUpType === "delete") {
      setDeletePopUpMenu(false)
    } else if (popUpMenuDetails.popUpType === "rename") {
      setRenamePopUpMenu(false)
    }
    setPopUpMenuDetails(null)
  }

  const refresh = async () => {
    if (refreshAllowed) {
      setRefreshInProgress(true)
      setRefreshAllowed(false)
      setRefreshInProgress(false)
      setTimeout(() => setRefreshAllowed(true), 3000)
    }
  }

  const deleteRecording = async () => {
    setLoading(true)
    await API.post("ZenoApp", "/deleteRecording", {
      headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      body: {
        conversation_id: popUpMenuDetails.conversation_id,
      },
    })
    refresh()
    setLoading(false)
    closeMenuPopUp()
  }

  const startNewAttempt = async () => {
    const moduleObj = location.state
    navigate("/chatRoomPage", { state: moduleObj })

  }

  const DeletePopUpMenuDiv = deletePopUpMenu ? (
    <div className={`frostedBackground is-visible`}>
      {loading ? (
        <div className="RecordingPopUp">
          <div className="loadingDiv">
            <l-grid size="120" speed="1.4" color="#3045FF"></l-grid>
          </div>
        </div>
      ) : (
          <div className="RecordingPopUp">
            <button className="backButtonRecordingInfo" onClick={closeMenuPopUp}></button>
            <label className="deletePopUpHeader">Delete "{popUpMenuDetails.conversation_title}" ?</label>
            <label className="deletePopUpSubHeader">Are you sure you want to delete this recording?</label>
            <button className="deleteRecordingButton" onClick={deleteRecording}>
              Delete Recording
          </button>
          </div>
        )}
    </div>
  ) : null


  const recordingsBoxContent =
    refreshInProgress ? (
      <div className="flex-1 px-8 flex flex-row content-center justify-center">
        <l-grid size="120" speed="1.4" color="#3045FF" className="mt-50" />
      </div>
    ) : !attemptArray.length ? (
      <div className="flex flex-col items-center mt-20">
        <p className="text-sm text-muted-foreground font-semibold mb-2">No Attempts</p>
        <p className="text-sm text-muted-foreground">Click "Run Sim" to get started!</p>
      </div>
    ) : (
          <div className="w-full px-6 flex flex-row flex-wrap">
            <Recordings
              recordings={attemptArray}
              name={"user"}
              handleMenuPopUp={openMenuPopUp}
              activityName={location.state.testName}
            />
          </div>
        )


  return (
    <>
      {pageLoading ? <AssignmentLandingPageSkeleton></AssignmentLandingPageSkeleton>
        : <div class="xl:pl-0 w-full">
          <main>
            <header>
              <nav class="flex overflow-x-auto border-b border-white/10 py-4">
                <div className="px-8">
                  <Breadcrumb>
                    <BreadcrumbList>
                      <BreadcrumbItem>
                        <BreadcrumbLink href="/assignmentsPage">Modules</BreadcrumbLink>
                      </BreadcrumbItem>
                      <BreadcrumbSeparator />
                      <BreadcrumbItem>
                        <BreadcrumbPage>{`${location.state.testName}`}</BreadcrumbPage>
                      </BreadcrumbItem>
                    </BreadcrumbList>
                  </Breadcrumb>
                </div>
              </nav>

              <div class="flex flex-col items-start justify-between gap-x-8 gap-y-4 bg-gray-400/10 px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8">
                <div>
                  <div class="flex items-center gap-x-3">
                    {modulePendingReview === 'Incomplete' || modulePendingReview === 'Pending' ?
                      <div class="flex-none rounded-full bg-yellow_light p-1 text-yellow">
                        <div class="h-2 w-2 rounded-full bg-current"></div>
                      </div>
                      : modulePendingReview === 'Passed' ?
                        <div class="flex-none rounded-full bg-green_light p-1 text-green">
                          <div class="h-2 w-2 rounded-full bg-current"></div>
                        </div>
                        : <div class="flex-none rounded-full bg-red-400/10 p-1 text-red-400">
                          <div class="h-2 w-2 rounded-full bg-current"></div>
                        </div>}

                    <h1 class="flex gap-x-3 text-base leading-7">

                      <span class="font-semibold text-grey">{location.state.testName}</span>
                    </h1>
                  </div>
                  <p class="mt-2 text-xs leading-6 text-gray-400">{`${location.state.testName} Summary`}</p>
                </div>
                {(modulePendingReview === "Not Passed" || modulePendingReview === "Incomplete") ? (
                  <button class="order-first flex-none rounded-full bg-indigo-400/10 px-2 py-1 text-s font-medium text-pareBlue ring-1 ring-inset ring-pareBlue sm:order-none" onClick={startNewAttempt}>Run Sim</button>

                ) : modulePendingReview == "Pending" ? (
                  <button disabled class="order-first flex-none rounded-full bg-indigo-400/10 px-2 py-1 text-s font-medium text-indigo-400 ring-1 ring-inset ring-indigo-400/30 sm:order-none">Pending</button>
                ) : modulePendingReview == "Passed" ? (
                  <button disabled class="order-first flex-none rounded-full bg-indigo-400/10 px-2 py-1 text-s font-medium text-indigo-400 ring-1 ring-inset ring-indigo-400/30 sm:order-none">Passed</button>
                ) : null}

              </div>

              <div class="grid grid-cols-1 bg-gray-400/10 sm:grid-cols-2 lg:grid-cols-4">
                <div class="border-t border-white/5 px-4 py-6 sm:px-6 lg:px-8">
                  <p class="text-sm font-medium leading-6 text-gray-400">Due Date</p>
                  <p class="mt-2 flex items-baseline gap-x-2">
                    <span class="text-4xl font-semibold tracking-tight text-grey">{moduleCatInfo.dueDate || "None"}</span>
                  </p>
                </div>
                <div class="border-t border-white/5 px-4 py-6 sm:border-l sm:px-6 lg:px-8">
                  <p class="text-sm font-medium leading-6 text-gray-400">Duration</p>
                  <p class="mt-2 flex items-baseline gap-x-2">
                    <span class="text-4xl font-semibold tracking-tight text-grey">{moduleCatInfo.duration || "0"}</span>
                    <span class="text-sm text-gray-400">mins</span>
                  </p>
                </div>
                <div class="border-t border-white/5 px-4 py-6 sm:px-6 lg:border-l lg:px-8">
                  <p class="text-sm font-medium leading-6 text-gray-400">Number of attempts</p>
                  <p class="mt-2 flex items-baseline gap-x-2">
                    <span class="text-4xl font-semibold tracking-tight text-grey">{moduleCatInfo.numAttempts}</span>
                  </p>
                </div>
                <div class="border-t border-white/5 px-4 py-6 sm:border-l sm:px-6 lg:px-8">
                  <p class="text-sm font-medium leading-6 text-gray-400">Status</p>
                  <p class="mt-2 flex items-baseline gap-x-2">
                    <span class="text-4xl font-semibold tracking-tight text-grey">{modulePendingReview}</span>
                  </p>
                </div>
              </div>
            </header>
            <div className="mb-4 mt-4 px-6">
              <Tabs defaultValue="Overview" className="w-[100%]" onValueChange={setSelectedTab}>
                <TabsList>
                  <TabsTrigger value="Overview">Overview</TabsTrigger>
                  <TabsTrigger value="Attempts">Attempts</TabsTrigger>
                </TabsList>
              </Tabs>
            </div>
            {selectedTag === "Overview" ?
              <div class="px-8 ">
                <div class="mt-6 border-t border-gray-100">
                  <dl class="divide-y divide-gray-100">
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt class="text-sm font-medium leading-6 text-gray-900">Description</dt>
                      <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 whitespace-pre-line">{moduleCatInfo.description}</dd>
                    </div>
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt class="text-sm font-medium leading-6 text-gray-900">Objective</dt>
                      <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 whitespace-pre-line">{moduleCatInfo.objective}</dd>
                    </div>
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt class="text-sm font-medium leading-6 text-gray-900">Call Type</dt>
                      <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 whitespace-pre-line">{moduleCatInfo.testType}</dd>
                    </div>
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt class="text-sm font-medium leading-6 text-gray-900">Persona Summary</dt>
                      <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 whitespace-pre-line">{moduleCatInfo.persona_summary}</dd>
                    </div>
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt class="text-sm font-medium leading-6 text-gray-900">Scenario Background</dt>
                      <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 whitespace-pre-line">{moduleCatInfo.scenario}</dd>
                    </div>
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt class="text-sm font-medium leading-6 text-gray-900">Links</dt>
                      <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 whitespace-pre-line">{moduleCatInfo.links === "" ? "N/A" : moduleCatInfo.links.split(",").map(link => (
                        <button
                          key={`link_button_${link}`}
                          onClick={() => {
                            const url = link.startsWith("http://") || link.startsWith("https://") ? link : `https://${link}`;
                            window.open(url, "_blank")
                          }
                          }
                          className="rounded-md shadow-md p-2 hover:bg-gray-200">
                          <p className="line-clamp-2 font-semibold text-md text-left">{link}</p>
                        </button>
                      ))}</dd>
                    </div>

                  </dl>
                </div>
              </div>
              : <>{recordingsBoxContent}</>}
          </main>
        </div>}
    </>
  )
}

export default observer(AssignmentLandingPage)
