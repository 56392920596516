// DropdownSelector.jsx
import React from 'react';

const DropdownSelector = ({ label, options, value, onChange }) => {
  return (
    <div>
      <label htmlFor="selector" className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <select
        id="selector"
        name="selector"
        value={value || ''}
        onChange={onChange}
        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-8 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 truncate"
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DropdownSelector;
