import React from "react"
import { useState, useEffect } from "react"

const Timer = ({ initialMinute, initialSeconds, endRoom }) => {
  const [minutes, setMinutes] = useState(initialMinute)
  const [seconds, setSeconds] = useState(initialSeconds)

  const progress = ((initialMinute * 60 - (minutes * 60 + seconds)) / (initialMinute * 60)) * 100

  const timeline = {
    width: "100%",
    height: "4px",
    background: "#ADADAD",
    left: "0%",
    top: "0%",
  }
  const timelineFill = {
    width: `${progress}%`,
    height: "4px",
    background: "#3045ff",
    left: `0%`,
  }
  const timerStyle = {
    left: "0%",
    fontSize: "1.2vw",
  }

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval)
          endRoom()
        } else {
          setMinutes(minutes - 1)
          setSeconds(59)
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval)
    }
  })

  return (
    <div class="w-full">
      {minutes === 0 && seconds === 0 ? null : (
        <div>
          <h1 style={timerStyle}>
            {" "}
            {Math.abs(initialMinute - 1 - minutes)}:{60 - seconds < 10 ? `0${60 - seconds}` : 60 - seconds}/
            {initialMinute}:00
          </h1>
          <div style={timeline}>
            <div style={timelineFill}></div>
          </div>{" "}
        </div>
      )}
    </div>
  )
}

export default Timer
