import React, { useEffect, useState, useRef } from "react"
import {
  ControlBar,
  GridLayout,
  LiveKitRoom,
  ParticipantTile,
  RoomAudioRenderer,
  useTracks,
  BarVisualizer,
  useVoiceAssistant,
  useLocalParticipant,
  useRoomContext
} from '@livekit/components-react';
import { useLocation, useNavigate } from "react-router-dom"
import '@livekit/components-styles';
import { Track, RoomEvent } from 'livekit-client';
import { Auth, API } from "aws-amplify"
import DoubleOptionPopUp from "../../components/PopUps/DoubleOptionPopUp"
import { useStores } from "../../models/root-store"
import { v4 as uuid } from "uuid"
import { quantum } from "ldrs"
import Timer from "../../components/Timer/Timer"


quantum.register()
const pareLogo = require("./ChatPageIcons/PareLogo_new.png")

const ChatRoomPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const conversation_id = useRef(uuid())
  const videoID = useRef(uuid())
  const { userStore, recordingListStore } = useStores()
  const { userId, userEmail, managerId, name, type } = userStore.user || {}
  const [roomConfig, setRoomConfig] = useState({ serverUrl: null, token: null, roomName: null, egressId: null })
  const [displayDisconnectPopUp, setDisplayDisconnectPopUp] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const [hangUpSignaled, setHangUpSiglned] = useState(false)
  const [transcript, setTranscript] = useState([])
  const [timerExceeded, setTimerExceeded] = useState(false)

  useEffect(() => {
    const createRoom = async () => {
      const LKPayload = await API.get("ZenoApp", "/getLKToken", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        queryStringParameters: {
          company: userStore.user.company,
          firstName: userStore.user.firstName,
          roleplay_summary: location.state.roleplay_history,
          voice: location.state.voice,
          presentation: location.state.presentation,
          assistant_id: location.state.roleplay_assistant_id,
          timeLimit: location.state.timeLimit,
          agent_type: type === 'Manager' || type === 'Admin' ? "multimodal" : "voice_pipeline",
          personaName: location.state.persona_display_name
        },
      })
      setRoomConfig(LKPayload)
      setPageLoading(false)

    }

    createRoom()
  }, [])

  const handleOnLeave = async () => {
    if (type !== 'Manager' && type !== 'Admin') {
      API.get("ZenoApp", "/manageEgress", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        queryStringParameters: {
          roomName: null,
          egressId: roomConfig.egressId,
          egressStatus: 'stop'
        },
      })
    }
    setDisplayDisconnectPopUp(true)

  }

  const signalTimerExceeded = () => {
    setTimerExceeded(true)
  }
  const handleHangUp = () => {
    setHangUpSiglned(true)
  }
  const clearConvoInfo = () => {
    sessionStorage.setItem("simulationReady", "false")
  }

  const dontSave = () => {
    clearConvoInfo()
    if (type === "Manager" || type === "Admin") {
      navigate("/managerDev")
    } else {
      navigate("/assignmentsPage")
    }
  }
  const sendTranscriptToDB = async () => {

    clearConvoInfo()
    const activity_id = location.state.activity_id
    const callType = location.state.callType
    const savedAttempt = {
      transcript,
      conversation_id: conversation_id.current,
      user_id: userId,
      email: userEmail,
      name: name,
      video_id: videoID.current,
      callType,
      activity_id,
      manager_id: managerId,
      deleted: "N",
      fully_processed: "N",
    }
    await API.post("ZenoApp", "/sendAudioFile", {
      headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      body: savedAttempt,
    })
    recordingListStore.addAttempt(savedAttempt)
    navigate("/assignmentsPage")
  }

  const restartConversation = () => {
    window.location.reload()
  }

  const startEgress = async () => {
    if (type !== 'Manager' && type !== 'Admin') {
      const egressObj = await API.get("ZenoApp", "/manageEgress", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        queryStringParameters: {
          roomName: roomConfig.roomName,
          egressId: null,
          egressStatus: 'start',
          conversation_id: conversation_id.current,
          video_id: videoID.current
        },
      })
      setRoomConfig(prev => ({ ...prev, egressId: egressObj.egressId }))
    }

  }

  const disconnectPopUp = displayDisconnectPopUp && (type === "Manager" || type === "Admin") && timerExceeded ?
    <DoubleOptionPopUp header={"You ran out of time!"} body={`You ran out of time while testing!`} rightButtonFunction={() => navigate("/managerDev")} rightButtonLabel="Exit Test" leftButtonFunction={restartConversation} leftButtonLabel="Restart Test"></DoubleOptionPopUp>
    : displayDisconnectPopUp && (type === "Manager" || type === "Admin") && hangUpSignaled ?
      <DoubleOptionPopUp header={"You got hung up on!"} body={`The prospect hung up on you. Exit the test or test again.`} rightButtonFunction={() => navigate("/managerDev")} rightButtonLabel="Exit Test" leftButtonFunction={restartConversation} leftButtonLabel="Restart Test"></DoubleOptionPopUp>
      : displayDisconnectPopUp && (type === "Manager" || type === "Admin") ?
        <DoubleOptionPopUp header={"Nice job testing!"} body={`Nice job! If you’re happy with your test, exit the roleplay environment, or restart.`} rightButtonFunction={() => navigate("/managerDev")} rightButtonLabel="Exit Test" leftButtonFunction={restartConversation} leftButtonLabel="Restart Test"></DoubleOptionPopUp>
        : displayDisconnectPopUp && timerExceeded ?
          <DoubleOptionPopUp header={"You ran out of time!"} body={`You can either save this attempt or don't save and start a new one!`} rightButtonFunction={sendTranscriptToDB} rightButtonLabel="Save" leftButtonFunction={dontSave} leftButtonLabel="Don't Save"></DoubleOptionPopUp>
          : displayDisconnectPopUp && hangUpSignaled ?
            <DoubleOptionPopUp header={"You got hung up on by the prospect!"} body={`You got hung up on, the prospect was no longer interested. Save this attempt or dan't save and start a new one.`} rightButtonFunction={sendTranscriptToDB} rightButtonLabel="Save" leftButtonFunction={dontSave} leftButtonLabel="Don't Save"></DoubleOptionPopUp>
            : displayDisconnectPopUp ?
              <DoubleOptionPopUp header={"Nice job role playing!"} body={`Now just save your recording below so we can process it for analytics, or don't save and restart.`} rightButtonFunction={sendTranscriptToDB} rightButtonLabel="Save" leftButtonFunction={dontSave} leftButtonLabel="Don't Save"></DoubleOptionPopUp>
              : null

  return (
    <>
      {pageLoading ?
        <div class="h-full xl:pl-0 w-full bg-pareBlue flex items-center justify-center">
          <div className="w-[30%] h-[30%] flex flex-col items-center justify-center">
            <p class="text-white semi-bold text-lg leading-7">Generating Conversation</p>
            <div className="QuantumLoaderDiv">
              <l-quantum size="60" speed="1.75" color="white"></l-quantum>
            </div>
          </div>
        </div>
        : <LiveKitRoom
          video={true}
          connect={true}
          audio={true}
          token={roomConfig.token}
          serverUrl={roomConfig.serverUrl}
          onConnected={startEgress}
          onDisconnected={handleOnLeave}
          // Use the default LiveKit theme for nice styles.
          data-lk-theme="default"
          style={{ height: '100vh' }}
        >
          <img src={pareLogo} className="w-3/4 mb-4 md:w-10 rounded-xl mt-4 ml-0 md:ml-4 md:mb-4" alt="logo" />
          <MyVideoConference setTranscript={setTranscript} handleHangUp={handleHangUp} />
          <RoomAudioRenderer />
          <CustomControlBar timeLimit={location.state.timeLimit} signalTimerExceeded={signalTimerExceeded} />
        </LiveKitRoom>
      }
      {disconnectPopUp}
    </>
  )
}


function SimpleVoiceAssistant() {
  const { state, audioTrack } = useVoiceAssistant();

  return (
    <>
      <div className="h-full">
        {!audioTrack ?
          <p className="text-center">{state}</p>
          :
          <BarVisualizer state={state} barCount={7} trackRef={audioTrack} style={{}} />
        }
      </div>
    </>
  );
}
function MyVideoConference({ setTranscript, handleHangUp }) {
  const { localParticipant } = useLocalParticipant();
  const Room = useRoomContext();
  const [transcriptions, setTranscriptions] = useState({});
  // console.log(transcriptions)
  const handleScriptUpdate = (utterance) => {
    setTranscript(speakerTurns => [...speakerTurns, utterance])
  }

  useEffect(() => {

    const updateTranscriptions = (segments, participant, publication) => {
      setTranscriptions((prev) => {
        const newTranscriptions = { ...prev };
        for (const segment of segments) {
          if (segment.final) {
            const utterance = { text: segment.text, user: null, start: new Date(segment.firstReceivedTime), end: new Date(segment.lastReceivedTime) }
            if (participant ?.isAgent) {
              utterance.user = 'bot'
              console.log(segment.text)
              if (segment.text === 'I am going to drop now.') {
                handleHangUp()
                Room.disconnect()
              }
            } else {
              utterance.user = 'user'
            }
            handleScriptUpdate(utterance)
            newTranscriptions[segment.id] = segment;
          }
        }
        return newTranscriptions;
      });
    };
    const enableMedia = async () => {
      console.log('EnableMedia')
      if (localParticipant) {
        console.log('localParticipant')
        if (Room.state === 'connected') {
          await localParticipant.setCameraEnabled(true);
          await localParticipant.setMicrophoneEnabled(true)
        } else {
          console.warn("Room is not connected yet.");
        }
      }
    };

    Room.on(RoomEvent.TranscriptionReceived, updateTranscriptions);

    // if (!localParticipant.isCameraEnabled || !localParticipant.isMicrophoneEnabled) {
    enableMedia();
    // }


    return () => {
      // Disable the camera when the component unmounts
      if (localParticipant) {
        localParticipant.setCameraEnabled(false);
        localParticipant.setMicrophoneEnabled(false)
      }
      Room.off(RoomEvent.TranscriptionReceived, updateTranscriptions);
    };
  }, [Room, localParticipant]);


  const tracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: true },
      // { source: Track.Source.ScreenShare, withPlaceholder: false },
    ],
    { onlySubscribed: false },
  );
  const humanTracks = tracks.filter(x => x.participant.identity == 'human')

  return (
    <GridLayout tracks={humanTracks} style={{ height: 'calc(70vh - var(--lk-control-bar-height))', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px', marginTop: '10vh' }}>
      <>
        <ParticipantTile />
        <SimpleVoiceAssistant />
      </>
    </GridLayout>
  );
}

const CustomControlBar = ({ timeLimit, signalTimerExceeded }) => {
  const Room = useRoomContext();
  const endRoom = () => {
    signalTimerExceeded()
    Room.disconnect()
  }
  return (
    <>
      <Timer
        initialMinute={timeLimit}
        initialSeconds={0}
        endRoom={endRoom}></Timer>
      <ControlBar
        controls={{
          leave: true,
          screenShare: false,
          microphone: false,
          camera: false
        }}
      >

      </ControlBar>

    </>
  );
};

export default ChatRoomPage