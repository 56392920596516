// ScatterPlot.jsx
import React, { useMemo, useState, useEffect } from 'react';
import { Scatter } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

// Register Chart.js components and plugins
Chart.register(...registerables);

// Helper function to get initials from a name
const getInitials = (name) => {
  if (!name) return '';
  const [firstName, lastName] = name.split(' ');
  return `${firstName?.[0] || ''}${lastName?.[0] || ''}`.toUpperCase();
};

const ScatterPlot = ({ data, selectedUserEmail }) => {
  const [preloadedImages, setPreloadedImages] = useState({});

  // Preload profile images
  useEffect(() => {
    const loadImages = async () => {
      const images = {};
      for (const rep of data) {
        if (rep.profilePicture) {
          const img = new Image();
          img.src = rep.profilePicture;
          await new Promise((resolve) => {
            img.onload = resolve;
          });
          images[rep.email] = img;
        }
      }
      setPreloadedImages(images);
    };
    loadImages();
  }, [data]);

  // Prepare chart data
  const chartData = useMemo(() => {
    if (!data || data.length === 0) {
      return {
        datasets: [{
          label: 'No Data Available',
          data: [],
        }],
      };
    }

    const chartPoints = data.map(rep => ({
      x: rep.activity || 0,
      y: rep.rvi || 0,
      name: rep.name,
      profilePicture: rep.profilePicture,
      initials: getInitials(rep.name),
      email: rep.email || '', // Ensure email is a string
    }));

    // For debugging
    console.log('Chart Points Emails:', chartPoints.map(p => p.email));
    console.log('Selected User Email:', selectedUserEmail);

    // Calculate min and max values for x and y axes
    const minX = Math.min(...chartPoints.map(point => point.x));
    const maxX = Math.max(...chartPoints.map(point => point.x));
    const minY = Math.min(...chartPoints.map(point => point.y));
    const maxY = Math.max(...chartPoints.map(point => point.y));

    // Add buffer (e.g., 10% of the range) to min and max values
    let xBuffer = (maxX - minX) * 0.1;
    let yBuffer = (maxY - minY) * 0.1;
    if (xBuffer === 0) {
      xBuffer = minX * 0.1 || 1; // Use 10% of minX or default to 1
    }
    if (yBuffer === 0) {
      yBuffer = minY * 0.1 || 1; // Use 10% of minY or default to 1
    }

    return {
      datasets: [{
        label: 'EI / Activity',
        data: chartPoints,
        pointRadius: 20, // Size of the point
        pointStyle: 'circle',
        backgroundColor: 'rgba(0, 0, 0, 0)', // Transparent by default
        borderColor: 'rgba(0, 0, 0, 0)', // Transparent by default
        hoverBackgroundColor: 'rgba(75, 192, 192, 0.8)', // Highlight on hover
        hoverBorderColor: 'rgba(75, 192, 192, 1)', // Highlight border on hover
        hoverBorderWidth: 3, // Thicker border on hover
      }],
      xMin: minX - xBuffer,
      xMax: maxX + xBuffer,
      yMin: minY - yBuffer,
      yMax: maxY + yBuffer,
    };
  }, [data]);

  const selectedEmailNormalized = (selectedUserEmail || '').trim().toLowerCase();

  // Define the custom plugin
  const profilePicturePlugin = useMemo(() => ({
    id: 'profilePicturePlugin',
    afterDatasetsDraw: (chart) => {
      const ctx = chart.ctx;
      chart.data.datasets[0].data.forEach((point, index) => {
        const meta = chart.getDatasetMeta(0);
        if (!meta.data[index]) return; // Ensure the data point exists
        const { x, y } = meta.data[index].getProps(['x', 'y'], true);
        const img = preloadedImages[point.email];
        const initials = point.initials;

        // Normalize emails for comparison
        const pointEmailNormalized = (point.email || '').trim().toLowerCase();
        const isSelected = selectedEmailNormalized !== '' && pointEmailNormalized === selectedEmailNormalized;

        // Log for debugging
        console.log(`Point email: "${point.email}", Selected email: "${selectedUserEmail}", isSelected: ${isSelected}`);

        const radius = isSelected ? 25 : 20;
        ctx.save();
        ctx.beginPath();
        ctx.arc(x, y, radius, 0, 2 * Math.PI);
        ctx.fillStyle = isSelected ? '#ff0000' : '#3045ff';
        ctx.fill();

        if (img) {
          ctx.clip();
          ctx.drawImage(img, x - radius, y - radius, radius * 2, radius * 2);
        } else {
          ctx.fillStyle = '#fff';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.font = isSelected ? 'bold 18px Arial' : '16px Arial';
          ctx.fillText(initials, x, y);
        }

        if (isSelected) {
          ctx.beginPath();
          ctx.arc(x, y, radius + 3, 0, 2 * Math.PI);
          ctx.strokeStyle = '#ff0000';
          ctx.lineWidth = 2;
          ctx.stroke();
        }
        ctx.restore();
      });

      // Draw horizontal and vertical lines to bisect the chart
      const { chartArea } = chart;
      const midX = (chartArea.left + chartArea.right) / 2;
      const midY = (chartArea.top + chartArea.bottom) / 2;

      ctx.save();
      ctx.strokeStyle = 'blue';
      ctx.setLineDash([5, 5]); // Dotted line

      // Draw vertical line
      ctx.beginPath();
      ctx.moveTo(midX, chartArea.top);
      ctx.lineTo(midX, chartArea.bottom);
      ctx.stroke();

      // Draw horizontal line
      ctx.beginPath();
      ctx.moveTo(chartArea.left, midY);
      ctx.lineTo(chartArea.right, midY);
      ctx.stroke();

      // Draw labels for each quadrant outside the chart
      const quadrantLabels = {
        topLeft: "High Performer",
        topRight: "Star Talent",
        bottomLeft: "Talent Risk",
        bottomRight: "Star Potential"
      };

      ctx.font = "14px Arial";
      ctx.fillStyle = "#374151";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";

      // Top Left
      ctx.fillText(
        quadrantLabels.topLeft,
        chartArea.left + (midX - chartArea.left) / 2,
        chartArea.top - 20
      );

      // Top Right
      ctx.fillText(
        quadrantLabels.topRight,
        midX + (chartArea.right - midX) / 2,
        chartArea.top - 20
      );

      // Bottom Left
      ctx.fillText(
        quadrantLabels.bottomLeft,
        chartArea.left + (midX - chartArea.left) / 2,
        chartArea.bottom + 20
      );

      // Bottom Right
      ctx.fillText(
        quadrantLabels.bottomRight,
        midX + (chartArea.right - midX) / 2,
        chartArea.bottom + 20
      );

      ctx.restore();
    },
  }), [selectedUserEmail, preloadedImages]);

  // Prepare chart options
  const options = useMemo(() => ({
    scales: {
      x: {
        title: {
          display: true,
          text: 'Total Activity',
        },
        min: chartData.xMin, // Apply buffer
        max: chartData.xMax, // Apply buffer
        grid: {
          drawOnChartArea: false, // Remove all x-axis grid lines
          drawTicks: false, // Hide ticks
        },
        ticks: {
          callback: function(value, index, values) {
            return index === Math.floor(values.length / 2) ? value : ''; // Only show the center tick
          },
        },
      },
      y: {
        title: {
          display: true,
          text: 'Efficiency Index',
        },
        min: chartData.yMin, // Apply buffer
        max: chartData.yMax, // Apply buffer
        grid: {
          drawOnChartArea: false, // Remove all y-axis grid lines
          drawTicks: false, // Hide ticks
        },
        ticks: {
          callback: function(value, index, values) {
            return index === Math.floor(values.length / 2) ? value : ''; // Only show the center tick
          },
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          // Custom tooltip content
          label: function(tooltipItem) {
            const point = tooltipItem.raw;
            return `${point.name}: Activity ${point.x}, EI ${point.y}`;
          }
        },
        backgroundColor: 'rgba(0, 0, 0, 0.7)', // Dark background for tooltips
        titleFont: { size: 16 },
        bodyFont: { size: 14 },
        padding: 10,
        displayColors: false, // Remove the color box
      },
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
  }), [chartData]);

  return (
    <div style={{ height: '400px', width: '100%' }}>
      <Scatter
        key={selectedUserEmail || 'none'}
        data={chartData}
        options={options}
        plugins={[profilePicturePlugin]}
      />
    </div>
  );
};

export default ScatterPlot;
