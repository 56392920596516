import { APIQueryBuilder } from "./helpers"
import {
  GetProfilePictureFromS3Response,
  GetTeamProfilePicturesResponse,
  GetUserInfoResponse,
  GetSalesTeamResponse,
  GetModuleListResponse,
  GetRecordingListResponse,
} from "./api.types"

export async function getUserInfo(): Promise<GetUserInfoResponse> {
  try {
    const res = await APIQueryBuilder("/getUserInfo")
    return { kind: "ok", data: res.userInfo }
  } catch (e) {
    return { kind: "error" }
  }
}

export async function getProfilePictureFromS3(): Promise<GetProfilePictureFromS3Response> {
  try {
    const res = await APIQueryBuilder("/getProfilePicture")
    return { kind: "ok", data: res }
  } catch (e) {
    return { kind: "error" }
  }
}

export async function getTeamProfilePictures(userIds): Promise<GetTeamProfilePicturesResponse> {
  try {
    const stringyfiedUserIds = userIds.join(",")
    const res = await APIQueryBuilder("/getTeamProfilePictures", { stringyfiedUserIds })
    return { kind: "ok", data: res }
  } catch (e) {
    return { kind: "error" }
  }
}

export async function getVideo(recordings): Promise<GetProfilePictureFromS3Response> {
  try {
    const res = await APIQueryBuilder("/getVideo", { recordings })
    return { kind: "ok", data: res }
  } catch (e) {
    return { kind: "error" }
  }
}

export async function getSalesTeam(managerId, userType, company): Promise<GetSalesTeamResponse> {
  try {
    const res = await APIQueryBuilder("/getTeamData", { userType, company })
    const team = Object.keys(res.teamMap).map(x => {
      return { userId: x, name: res.teamMap[x].name, userEmail: res.teamMap[x].userEmail, lastLoginTime: res.teamMap[x].lastLoginDatetime, type: res.teamMap[x].type }
    })
    const inviteeList = res.inviteeList

    return { kind: "ok", data: { managerId, team, inviteeList } }
  } catch (e) {
    return { kind: "error" }
  }
}

export async function getAssignedModulesInfo(company): Promise<GetModuleListResponse> {
  try {
    const res = await APIQueryBuilder("/getAssignedModules", { company })
    console.log(res.involvedActivities)
    return { kind: "ok", data: res.involvedActivities }
  } catch (e) {
    return { kind: "error" }
  }
}

export async function getManagerModulesInfo(userType, company): Promise<GetModuleListResponse> {
  try {
    const res = await APIQueryBuilder("/getManagerActivities", { userType, company })

    return { kind: "ok", data: res.managerActivities }
  } catch (e) {
    return { kind: "error" }
  }
}

export async function getUserRecordingInfo(activityId): Promise<GetRecordingListResponse> {
  try {
    const res = await APIQueryBuilder("/getUserRecordings", { activityId })

    return { kind: "ok", data: res }
  } catch (e) {
    return { kind: "error" }
  }
}

export async function getModuleAttemptsInfo(activityId: string): Promise<GetRecordingListResponse> {
  try {

    const res = await APIQueryBuilder("/getModuleAttempts", { activityId })

    return { kind: "ok", data: res }
  } catch (e) {
    return { kind: "error" }
  }
}

