import React from "react"
import { useNavigate } from "react-router-dom"
import { Auth } from "aws-amplify"
import awsmobile from "../../aws-exports"
import "./IndividualActivityBox.css"
import { useStores } from "../../models/root-store"
import { ClockIcon, CalendarDaysIcon, PhoneIcon } from '@heroicons/react/20/solid'

Auth.configure(awsmobile)

const IndividualActivityBox = ({ type, activityInfo }) => {
  const { userStore } = useStores()
  const { userId } = userStore.user || {}
  let navigate = useNavigate()

  const toRecordingDetails = () => {
    navigate("/assignmentLandingPage", { state: activityInfo })
  }

  const status = JSON.parse(activityInfo.testParticipants)[userId].status
  const completedActivity = status === "Pending" || status === "Passed"
  const analysisInProgress = status === "In Progress"
  const individualDueDate = JSON.parse(activityInfo.testParticipants)[userId].dueDate
  const friendlyFormatDate = individualDueDate ? `Due ${new Date(individualDueDate).toDateString()}` : 'N/A'
  const stringToDate = new Date(individualDueDate)
  const epochDueDate = stringToDate.getTime()
  const today = new Date().toDateString()
  const epochToday = new Date(today).getTime()
  const overdue = epochDueDate < epochToday

  const RecordingStatusLabelStyle = {
    background:
      status === "Pending" ? "rgba(255, 199, 0)" : status === "Passed" ? "rgba(57, 202, 34)" : "rgba(255, 94, 94)",
    fontSize: "9px",
    color: "white",
    borderRadius: "5px",
    position: "absolute",
    top: "85%",
    right: "5%",
    padding: "5px",
    minWidth: "60px",
    display: "inline-block",
    textAlign: "center",
    fontWeight: 500,
  }

  return (
    <div className="lg:col-start-3 lg:row-end-1 w-[280px] h-[280px] shadow-[4px_4px_4px_4px_rgba(0,0,0,0.25)] m-[10px] rounded-[5px] relative border border-transparent hover:border-pareBlue cursor-pointer" onClick={toRecordingDetails}>
      <h2 className="sr-only">Summary</h2>
      <div className="rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5 h-full">
        <dl className="flex flex-wrap">
          <div className="flex-auto pl-6 pt-6">
            <dt className="text-sm/6 font-semibold text-gray-900">{activityInfo.testName}</dt>
            {/* <dd className="mt-1 text-base font-semibold text-gray-900">$10,560.00</dd> */}
          </div>
          <div className="flex-none self-end px-6 pt-4">
            <dt className="sr-only">Status</dt>
            {overdue && !analysisInProgress ? (
              <dd className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                Overdue
            </dd>
            ) : null}
          </div>
          <div className="mt-6 flex w-full flex-none gap-x-4 border-t border-gray-900/5 px-6 pt-6">
            <dt className="flex-none">
              <span className="sr-only">Client</span>
              <ClockIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
            </dt>
            <dd className="text-sm/6 text-gray-500">{`${activityInfo.timeLimit} minutes`}</dd>
          </div>
          <div className="mt-4 flex w-full flex-none gap-x-4 px-6 overflow-hidden text-ellipsis">
            <dt className="flex-none">
              <span className="sr-only">Due date</span>
              <CalendarDaysIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
            </dt>
            <dd className="text-sm/6 text-gray-500">
              <div >{`${friendlyFormatDate}`}</div>
            </dd>
          </div>
          <div className="mt-4 flex w-full flex-none gap-x-4 px-6 overflow-hidden text-ellipsis">
            <dt className="flex-none">
              <span className="sr-only">Due date</span>
              <PhoneIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
            </dt>
            <dd className="text-sm/6 text-gray-500">
              <div >{`${activityInfo.testType}`}</div>
            </dd>
          </div>
        </dl>
        <div className="mt-6 border-t border-gray-900/5 px-6 flex justify-center items-center h-[50px]">
          {!analysisInProgress ? (
            <a href="#" className="text-sm/6 font-semibold text-pareBlue">
              Start <span aria-hidden="true" className="text-pareBlue">&rarr;</span>
            </a>
          ) : (
              <div className="w-full h-full text-sm/6 font-semibold text-pareBlue flex items-center justify-end">Analyzing...</div>
            )}

        </div>
      </div>
    </div>
  )
}

export default IndividualActivityBox
