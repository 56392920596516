// ChecklistAlert.jsx
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'

export default function ChecklistAlert({ onDetailsClick }) {
  return (
    <div className="inline-flex rounded-md bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon aria-hidden="true" className="h-5 w-5 text-yellow-400" />
        </div>
        <div className="ml-3 md:flex md:justify-between">
          <div>
            <h3 className="text-sm font-medium text-yellow-800">Attention needed</h3>
            <div className="mt-2 text-sm text-yellow-700">
              <p>
                Tasks need to be completed before we can populate this data.
              </p>
            </div>
          </div>
          <p className="mt-3 text-sm md:ml-6 md:mt-0">
            <button
              onClick={onDetailsClick}
              className="whitespace-nowrap font-medium text-yellow-700 hover:text-yellow-600"
            >
              Details
              <span aria-hidden="true"> &rarr;</span>
            </button>
          </p>
        </div>
      </div>
    </div>
  )
}
