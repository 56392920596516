import React from 'react';
import { Line } from 'react-chartjs-2';

const MiniGraph = ({ data, userData, fieldKey, label }) => {
  const teamData = data || [];
  const individualData = userData || [];

  // Extract and merge dates
  const teamDates = teamData.map(entry => entry.date);
  const userDates = individualData.map(entry => entry.date);
  const allDatesSet = new Set([...teamDates, ...userDates]);
  const allDates = Array.from(allDatesSet).sort((a, b) => new Date(a) - new Date(b));

  // Map dates to values
  const teamDataMap = Object.fromEntries(teamData.map(entry => [entry.date, entry[fieldKey]]));
  const userDataMap = Object.fromEntries(individualData.map(entry => [entry.date, entry[fieldKey]]));

  const teamValues = allDates.map(date => teamDataMap[date] !== undefined ? teamDataMap[date] : null);
  const userValues = allDates.map(date => userDataMap[date] !== undefined ? userDataMap[date] : null);

  // Prepare datasets
  const datasets = [];

  if (teamData.length > 0) {
    datasets.push({
      label: '',
      data: teamValues,
      fill: false,
      borderColor: 'blue',
      borderWidth: 2,
      tension: 0.3,
      pointRadius: 2,
      pointHoverRadius: 5,
      pointHoverBorderWidth: 2,
      pointHoverBackgroundColor: 'red',
    });
  }

  if (individualData.length > 0) {
    datasets.push({
      label: '',
      data: userValues,
      fill: false,
      borderColor: 'red',
      borderWidth: 2,
      tension: 0.3,
      pointRadius: 2,
      pointHoverRadius: 5,
      pointHoverBorderWidth: 2,
      pointHoverBackgroundColor: 'red',
    });
  }

  const chartData = {
    labels: allDates,
    datasets,
  };

  const options = {
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
        beginAtZero: false,
      },
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            const value = context.raw;
            return `${label}: ${value}`;
          },
        },
        backgroundColor: '#333',
        titleFont: { size: 14 },
        bodyFont: { size: 12 },
        padding: 10,
      },
      hover: {
        mode: 'nearest',
        intersect: false,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 5,
        bottom: 5,
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
    },
  };

  // Handle the case where there's no data to display
  if (datasets.length === 0) {
    return (
      <div style={{ height: '60px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <p>No data available</p>
      </div>
    );
  }

  return (
    <div style={{ height: '60px', width: '100%' }}> {/* Mini graph size */}
      <Line data={chartData} options={options} />
    </div>
  );
};

export default MiniGraph;
