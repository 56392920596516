import React, { useEffect, useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { Auth, API } from "aws-amplify"
import Messages from "../../components/Messages/Messages"
import { quantum, mirage } from "ldrs"
import "./ActivityCreationPage.css"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../@shadcn_components/ui/breadcrumb"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"
import { v4 as uuid } from "uuid"


quantum.register()
mirage.register()

const ENDPOINT = process.env.REACT_APP_WS_ENDPOINT
let socket

const ActivityCreationPage = () => {
  const { userStore, moduleListStore } = useStores()
  const navigate = useNavigate()
  const [showBasicInfoStep, setShowBasicInfoStep] = useState(true)
  const [showContextStep, setShowContextStep] = useState(false)
  const [testName, setTestName] = useState("")
  const [timeLimit, setTimeLimit] = useState("")
  const [testDesc, setTestDesc] = useState("")
  const [testObj, setTestObj] = useState("")
  const [publishing, setPublishing] = useState(false)
  const [promptCreated, setPromptCreated] = useState(false)
  const [savedContext, setSavedContext] = useState(null)
  const [savedRubric, setSavedRubric] = useState(null)
  const [rubricFile, setRubricFile] = useState(null)
  const [contextFile, setContextFile] = useState(null)
  const [links, setLinks] = useState("")
  const convoIndex = useRef(0)
  const rubricFileInfo = useRef({})
  const contextFileInfo = useRef({})
  const splitConvoIndex = useRef(0)
  const contextCreationMessages = useRef([])
  const voiceOptions = {
    Sam: "Male 1",
    Bob: "Male 2",
    Jennifer: "Female 1",
    Bridgette: "Female 2",
    Margot: "Female 3",
    Alexa: "Female 4",
  }
  const [voice, setVoice] = useState("Sam")

  const [endContextConvo, setEndContextConvo] = useState(false)
  const [chatBotLoading, setChatBotLoading] = useState(false)
  const roleplayConvoOver = useRef(false)
  const [currBotSpeakerTurn, setCurrBotSpeakerTurn] = useState("")
  const [screenShareChecked, setScreenShareChecked] = useState(false)
  // const contexts = moduleListStore.getAllContexts()
  // const rubrics = moduleListStore.getAllRubrics()
  // const [rubricOptions, setRubricOptions] = useState(rubrics.reduce((acc, x) => {
  //   acc[`${x.rubric_id}`] = x.displayName
  //   return acc
  // }, {}))
  // const [contextOptions, setContextOptions] = useState(contexts.reduce((acc, x) => {
  //   acc[`${x.context_id}`] = x.displayName
  //   return acc
  // }, {}))

  const handleScreenShareCheckBoxChange = () => {
    setScreenShareChecked(!screenShareChecked)
  }

  const handleKeyDown = e => {
    e.target.style.height = "inherit"
    e.target.style.height = `${e.target.scrollHeight}px`
  }
  const [contextCreationInput, setContextCreationInput] = useState("")
  const handleContextCreationInputChange = e => {
    // handleKeyDown()
    setContextCreationInput(e.target.value)
  }

  const cleanupFunction = refresh => {

    sessionStorage.setItem("contextCreationRefresh", "N")
    const endPayloadObj = {
      user_id: userStore.user.userId,
      convoIndex: convoIndex.current,
      convoEnd: true,
      assistant_id: userStore.user.assistant_id,
    }
    const endConvoPayload = { action: "createassistantcontext", message: endPayloadObj }
    socket.send(JSON.stringify(endConvoPayload))
    if (!refresh) {
      socket.close()
    }
    contextCreationMessages.current = []
    convoIndex.current = 0
    roleplayConvoOver.current = null
    // rubric_id = null
    splitConvoIndex.current = 0
  }

  useEffect(() => {
    const fetchModules = async () => {
      if (!moduleListStore.userModules) {
        if (userStore.user.type === 'SDR' || userStore.user.type === 'AE') {
          await moduleListStore.getAssignedModules(userStore.user.company)
        } else {
          await moduleListStore.getManagerModules(userStore.user.type, userStore.user.company)
        }
      }
    }
    fetchModules()
    let botSpeech = ""
    socket = new WebSocket(ENDPOINT)
    socket.onopen = () => {
      console.log("Succesfully opened WS")
      if (window.performance) {
        if (performance.navigation.type == 1) {
          console.log("This page is reloaded")
          if (sessionStorage.getItem("contextCreationRefresh") === 'Y') {
            cleanupFunction(true)
          } else {
            sessionStorage.setItem("contextCreationRefresh", "N")
          }
        } else {
          console.log("This page is not reloaded")
        }
      }

      const payloadObj = {
        user_id: userStore.user.userId,
        convoIndex: convoIndex.current,
        assistant_id: userStore.user.assistant_id,
      }
      const payload = { action: "createassistantcontext", message: payloadObj }
      socket.send(JSON.stringify(payload))

      setChatBotLoading(true)
    }
    socket.onerror = () => {
      console.log("error")
      alert("Please refresh the page to continue. The session has timed out.")
      setShowBasicInfoStep(true)
    }
    socket.onclose = () => {
      // alert('Please refresh the page to continue. The session has timed out.')
      setShowBasicInfoStep(true)
    }
    socket.onmessage = async event => {
      console.log(event.data)
      const eventObj = JSON.parse(event.data)
      const botSpeechSegment = eventObj.sentence
      if (
        botSpeechSegment.trim().match(/--CONFIRMED--/gm) ||
        botSpeechSegment.trim().match(/### --CONFIRMED--/gm) ||
        botSpeechSegment.trim().match(/\*\*--CONFIRMED--\*\*/gm)
      ) {
        console.log("received CONFIRMATION")
        if (!roleplayConvoOver.current) {
          roleplayConvoOver.current = true
          splitConvoIndex.current = contextCreationMessages.current.length
        }
        if (eventObj.orderIdx === 0) {
          botSpeech += botSpeechSegment.replace(/--CONFIRMED--/gm, "").trim()
          receiveResponse({ user: "Pare", text: botSpeech })
        } else {
          botSpeech += botSpeechSegment.replace(/--CONFIRMED--/gm, "").trim()
          contextCreationMessages.current[contextCreationMessages.current.length - 1].text = botSpeech
            .replace(/###/gm, "")
            .replace(/\*\*/gm, "")
          setCurrBotSpeakerTurn(botSpeech)
        }
      } else if (
        botSpeechSegment.trim().match(/--RUBRICCONFIRMED--/gm) ||
        botSpeechSegment.trim().match(/### --RUBRICCONFIRMED--/gm) ||
        botSpeechSegment.trim().match(/\*\*--RUBRICCONFIRMED--\*\*/gm)
      ) {
        if (roleplayConvoOver.current) {
          setEndContextConvo(true)
        } else {
          alert("Something went wrong in the creation process, please refresh to restart the process")
        }
        if (eventObj.orderIdx === 0) {
          botSpeech += botSpeechSegment.replace(/--RUBRICCONFIRMED--/gm, "").trim()
          receiveResponse({ user: "Pare", text: botSpeech })
        } else {
          botSpeech += botSpeechSegment.replace(/--RUBRICCONFIRMED--/gm, "").trim()
          contextCreationMessages.current[contextCreationMessages.current.length - 1].text = botSpeech
            .replace(/###/gm, "")
            .replace(/\*\*/gm, "")
          setCurrBotSpeakerTurn(botSpeech)
        }
      } else if (botSpeechSegment.trim() === "DONE") {
        console.log("DONE")
      } else {
        if (eventObj.orderIdx === 0) {
          botSpeech += botSpeechSegment
          receiveResponse({ user: "Pare", text: botSpeech })
        } else {
          botSpeech += botSpeechSegment
          contextCreationMessages.current[contextCreationMessages.current.length - 1].text = botSpeech
            .replace(/###/gm, "")
            .replace(/\*\*/gm, "")
          setCurrBotSpeakerTurn(botSpeech)
        }
      }
      if (botSpeechSegment === "DONE") {
        botSpeech = ""
        convoIndex.current += 1
        stopLoading()
      }
    }

    return () => {
      cleanupFunction(false)
    }
  }, [])


  const uploadContextFileToAssistant = async (contextFileName, filekey) => {
    const resBody = await API.post("ZenoApp", "/sendPersonaFile", {
      headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      body: {
        personaFilename: contextFileName,
        filekey,
      },
    })
    return resBody.file_id
  }
  const uploadRubricFileToAssistant = async (rubricFileName, filekey) => {
    const resBody = await API.post("ZenoApp", "/sendRubricFile", {
      headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      body: {
        rubricFileName,
        filekey,
      },
    })
    return resBody.file_id
  }
  const receiveResponse = botSpeakerTurn => {
    contextCreationMessages.current.push(botSpeakerTurn)
    setChatBotLoading(true)
  }
  const stopLoading = async () => {
    await wait(1000)
    setChatBotLoading(false)
  }

  function handleContextFileChange(e) {
    if (e.target.files[0] && /\.(doc|pdf)$/i.test(e.target.files[0].name)) {
      setContextFile(e.target.files[0])
      setSavedContext(null)
      // if(contextFile){
      //   contextCreationMessages.pop()
      // }
      contextCreationMessages.current.push(
        { user: "user", text: e.target.files[0].name },
        {
          user: "Pare",
          text: `Are you sure you want want to upload ${e.target.files[0].name}?`,
          type: "contextFileUploadButton",
        },
      )
    } else {
      alert("Please upload a .doc or pdf formatted file.")
    }
  }

  const handleRubricFileChange = e => {
    if (e.target.files[0] && /\.(doc|pdf)$/i.test(e.target.files[0].name)) {
      setRubricFile(e.target.files[0])
      setSavedRubric(null)
      // if(rubricFile){
      //   contextCreationMessages.pop()
      // }
      contextCreationMessages.current.push(
        { user: "user", text: e.target.files[0].name },
        {
          user: "Pare",
          text: `Are you sure you want want to upload ${e.target.files[0].name}?`,
          type: "rubricFileUploadButton",
        },
      )
    } else {
      alert("Please upload a .doc or pdf formatted file.")
    }
  }

  const transitionToContextStep = () => {
    if (testName.trim().length > 0 && testName.trim().length < 27) {
      if (timeLimit.trim().length > 0 && testDesc.trim().length > 0 && testObj.trim().length > 0 && voice) {
        if (parseInt(timeLimit) > 0 && parseInt(timeLimit) < 31) {
          setShowBasicInfoStep(false)
          setShowContextStep(true)
        } else {
          alert("Please enter a value between 1 and 30")
        }
      } else {
        alert("Please fill out all the required information")
      }
    } else {
      alert("Please fill out the module title and make sure it is less than 27 characters")
    }
  }

  const uploadContextFile = async () => {
    setChatBotLoading(true)
    const signedUrlContextUpload = await API.get("ZenoApp", "/getSignedUrlDoc", {
      headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      queryStringParameters: {
        contextFile: contextFile ? contextFile.name : null,
      },
    })

    const context_id = signedUrlContextUpload.context_id
    const contextFileName = contextFile ? contextFile.name : null
    const context_file_key = signedUrlContextUpload.file_key
    const contextFileType = contextFile.name.replace(/.*\./, "")
    await fetch(signedUrlContextUpload.contextSignedUploadUrl, {
      method: "PUT",
      headers: { "Content-Type": contextFileType === "pdf" ? "application/pdf" : "application/msword" },
      body: contextFile,
    })
    const context_file_id = await uploadContextFileToAssistant(contextFileName, context_file_key)
    contextFileInfo.current = { context_id, context_file_key, contextFileName, contextFileType }

    const payloadObj = {
      user_id: userStore.user.userId,
      convoIndex: convoIndex.current,
      context_file_id,
      assistant_id: userStore.user.assistant_id,
    }
    const payload = { action: "createassistantcontext", message: payloadObj }
    socket.send(JSON.stringify(payload))
    convoIndex.current += 1
  }
  const uploadRubricFile = async () => {
    setChatBotLoading(true)
    const signedUrlRubricUpload = await API.get("ZenoApp", "/getSignedUrlDoc", {
      headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      queryStringParameters: {
        rubricFile: rubricFile ? rubricFile.name : null,
      },
    })
    const rubric_id = signedUrlRubricUpload.rubric_id
    const rubric_file_key = signedUrlRubricUpload.file_key
    const rubricFileName = rubricFile ? rubricFile.name : null
    const rubricFileType = rubricFile.name.replace(/.*\./, "")

    await fetch(signedUrlRubricUpload.rubricSignedUploadUrl, {
      method: "PUT",
      headers: { "Content-Type": rubricFileType === "pdf" ? "application/pdf" : "application/msword" },
      body: rubricFile,
    })
    const rubric_file_id = await uploadRubricFileToAssistant(rubricFileName, rubric_file_key)
    rubricFileInfo.current = { rubric_id, rubric_file_key, rubricFileName, rubricFileType }
    const payloadObj = {
      user_id: userStore.user.userId,
      convoIndex: convoIndex.current,
      rubric_file_id,
      assistant_id: userStore.user.assistant_id,
    }
    const payload = { action: "createassistantcontext", message: payloadObj }
    socket.send(JSON.stringify(payload))
    convoIndex.current += 1
  }

  const createModule = async () => {
    if (!publishing) {
      setPublishing(true)
      const newModule = {
        activity_id: uuid(),
        testName: testName,
        timeLimit: timeLimit,
        testType: null,
        testDecription: testDesc,
        context_id: contextFileInfo.current.context_id,
        contextFilename: contextFileInfo.current.contextFileName,
        context_file_key: contextFileInfo.current.context_file_key,
        rubricFilename: rubricFileInfo.current.rubricFileName,
        contextPreset: savedContext,
        rubric_id: rubricFileInfo.current.rubric_id,
        rubric_file_key: rubricFileInfo.current.rubric_file_key,
        rubricPreset: savedRubric,
        company: userStore.user.company,
        testObj: testObj,
        links: links,
        voice: voice,
        roleplay_history: contextCreationMessages.current.slice(0, splitConvoIndex.current),
        rubric_history: contextCreationMessages.current.slice(splitConvoIndex.current, contextCreationMessages.current.length),
        presentation: screenShareChecked,
      }
      try {
        API.put("ZenoApp", "/createModule", {
          headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
          body: newModule,
        })
      } catch (e) {
        console.warn(e)
      }
      await wait(4000)
      const publishPayloadObj = {
        user_id: userStore.user.userId,
        convoEnd: true,
        assistant_id: userStore.user.assistant_id,
      }
      const publishModulePayload = { action: "createassistantcontext", message: publishPayloadObj }
      socket.send(JSON.stringify(publishModulePayload))
      // setPublishing(false)
      // going to need to add module here, however it won't be done by creation of said module so need to account for that 
      moduleListStore.addModule({
        activity_id: newModule.activity_id,
        testName: newModule.testName,
        testDecription: newModule.testDecription,
        timeLimit: newModule.timeLimit,
        company: newModule.company,
        testObj: newModule.testObj,
        voice: newModule.voice,
        links: newModule.links,
        doneCreation: "N",
        deleted: "N",
        presentation: newModule.presentation
      })
      navigate("/managerDev")
    } else {
      alert("Publishing already... ")
    }
  }

  const wait = delay => {
    return new Promise(res => setTimeout(res, delay))
  }

  const handleLinksChange = e => {
    setLinks(e.target.value)
  }
  const handleTestObjChange = e => {
    setTestObj(e.target.value)
  }
  const handleTimeLimitChange = e => {
    setTimeLimit(e.target.value)
  }
  const handleTestDescChange = e => {
    setTestDesc(e.target.value)
  }
  const handleTestNameChange = e => {
    setTestName(e.target.value)
  }

  const handleSavedContextChange = context => {
    setSavedContext(context)
    setContextFile(null)
  }
  const handleSavedRubricChange = rubric => {
    setSavedRubric(rubric)
    setRubricFile(null)
  }

  const respondToContextBot = async event => {
    event.preventDefault()
    if (contextCreationInput.trim().length > 0) {
      const exchange = { user: "user", text: contextCreationInput }
      contextCreationMessages.current.push(exchange)
      const payloadObj = {
        user_response: contextCreationInput,
        user_id: userStore.user.userId,
        convoIndex: convoIndex.current,
        assistant_id: userStore.user.assistant_id,
      }
      const payload = { action: "createassistantcontext", message: payloadObj }
      socket.send(JSON.stringify(payload))
      convoIndex.current += 1
      setContextCreationInput("")
      setChatBotLoading(true)
    }
  }

  return !promptCreated ? (
    <div className={`w-full h-full flex flex-col px-8 md:px-20 pt-8`}>
      <div className="mb-8">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href="/managerDev">Modules</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{`Create New Roleplay`}</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-base font-semibold leading-6 text-gray-900">Role Play Builder</h1>
          <p class="mt-2 text-sm text-gray-700">Create your modules with the role play builder</p>
        </div>
        {showBasicInfoStep ? <div class="mt-6 pb-8 pt-4 flex items-center justify-end gap-x-6">
          <button type="submit" class="rounded-md bg-pareBlue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" onClick={transitionToContextStep}>Continue</button>
        </div> : null}
      </div>
      {/* <PageTitle pageTitle={"Role Play Builder"}></PageTitle> */}
      {showBasicInfoStep ? (
        <div>
          <div class="mt-2 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">Enter a name for this role play module</label>
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <input placeholder="Enter your module name" value={testName} onChange={handleTestNameChange} type="text" name="first-name" id="first-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"></input>
              </div>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label for="last-name" class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">Provide a description for this role play module</label>
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <textarea placeholder="Enter your module description" value={testDesc} onChange={handleTestDescChange} type="text" name="last-name" id="last-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6"></textarea>
              </div>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label for="email" class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">Outline the rep’s objective for this role play module</label>
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <textarea placeholder="Enter your module objective" value={testObj} onChange={handleTestObjChange} id="email" name="email" type="text" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6"></textarea>
              </div>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label for="country" class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">Choose the voice type of your context</label>
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <select id="voices" name="voices" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  {Object.keys(voiceOptions).map((x, i) => (
                    <option key={i} value={x}>
                      {voiceOptions[x]}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label for="street-address" class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">Add link(s) to pre-work for reps to review before completing this role play module</label>
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <textarea placeholder="Enter links to trainings or documentation separated by commas" value={links} onChange={handleLinksChange} type="text" name="links" id="links" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-md sm:text-sm sm:leading-6"></textarea>
              </div>
            </div>

            <div class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label for="city" class="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">Set a time limit for this role (min)</label>
              <div class="mt-2 sm:col-span-2 sm:mt-0">
                <input type="number" placeholder="Select your module time limit (max 30 min)" min="1" max="30" value={timeLimit} onChange={handleTimeLimitChange} class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"></input>
              </div>
            </div>
          </div>

        </div>
      ) : (
          <div className="w-full h-full pb-20">
            <div className="w-full h-full rounded">
              {/* <div className=""> */}
              <Messages
                messages={contextCreationMessages.current}
                type={"Pare"}
                loading={chatBotLoading}
                uploadContextFile={uploadContextFile}
                uploadRubricFile={uploadRubricFile}
                messagesType={"roleplayCreation"}></Messages>
              {/* </div> */}
              <form class="pb-8" onSubmit={respondToContextBot}>
                {chatBotLoading ? (
                  <div className="w-full h-7 px-8 flex items-center  justify-center">
                    <l-mirage size="60" speed="1.4" color="grey"></l-mirage>
                  </div>
                ) : endContextConvo ? (
                  <div className="w-full h-7 flex items-center justify-center">
                    <div className="PublishModuleButton" onClick={createModule}>
                      {publishing ? "Publishing Module..." : "Publish Module"}
                    </div>
                  </div>
                ) : (
                      <>
                        <div class=" w-full flex items-end bg-gray-300 rounded-lg shadow-sm ">
                          <button class="UploadFileButton flex w-[55px] h-[30px] items-center justify-center text-gray-500 hover:text-blue-500 focus:outline-none mb-4 ml-2" onClick={() => document.getElementById('file-upload').click()}>
                            <input type="file" onChange={roleplayConvoOver.current ? handleRubricFileChange : handleContextFileChange} class="hidden" id="file-upload"></input>
                          </button>

                          <textarea
                            type="text"
                            placeholder="Type your message..."
                            class="flex-grow border-none focus:ring-0 outline-none resize-none overflow-hidden h-auto max-h-36 bg-gray-300 overflow-auto p-2"
                            onKeyDown={handleKeyDown}
                            value={contextCreationInput}
                            onChange={handleContextCreationInputChange}
                          />

                          <button class=" SubmitChatButton w-[70px] h-[30px] flex items-center justify-center bg-pareBlue text-white hover:text-blue-500 focus:outline-none mb-4 mr-4 rounded">

                          </button>
                        </div>
                      </>
                    )}
              </form>
            </div>
          </div>
        )}
    </div>
  ) : (
      <div class="h-full xl:pl-0 w-full bg-pareBlue flex items-center justify-center">
        <div className="w-[30%] h-[30%] flex flex-col items-center justify-center">
          <p class="text-white semi-bold text-lg leading-7">Generating Conversation</p>
          <div className="QuantumLoaderDiv">
            <l-quantum size="60" speed="1.75" color="white"></l-quantum>
          </div>
        </div>
      </div>
    )
}

export default observer(ActivityCreationPage)
