import React, { useMemo, useState  } from 'react';
import { Bar } from 'react-chartjs-2';
import SubCompetencyModal from './subCompModal';

const BarChart = ({ labels, data, topPerformerData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', description: '' });
  const chartData = useMemo(
    () => ({
      labels: labels || ['Cold Calling', 'Lead Qualification', 'Elevator Pitch', 'Handling Objections'],
      datasets: [
        {
          label: 'Selected User',
          data: data || [2, 3, 4, 2], // Original user data
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1,
        },
        {
          label: 'Baseline User',
          data: topPerformerData || [3, 2, 3, 4], // Sample baseline data
          backgroundColor: 'rgba(255, 159, 64, 0.2)',
          borderColor: 'rgba(255, 159, 64, 1)',
          borderWidth: 1,
        },
      ],
    }),
    [data]
  );

  const options = useMemo(() => ({
    scales: {
      y: {
        beginAtZero: true,
        max: 4,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            return 'Click for more info';
          }
        }
      }
    },    
    onHover: (event, chartElement) => {
      event.native.target.style.cursor = chartElement.length > 0 ? 'pointer' : 'default';
    },
    onClick: (event, chartElement) => {
      if (chartElement.length > 0) {
        const index = chartElement[0].index;
        const subCompetency = labels[index];
        const description = subCompetencyDescriptions[subCompetency] || "No description available";
        const userScore = data[index] || "N/A";
        setModalContent({ title: subCompetency, description, score: userScore });
        setIsModalOpen(true);
      }
    },
  }), [labels]);

  return (
    <div>
      <div style={{ height: '300px', width: '100%' }}>
        <Bar data={chartData} options={options} />
      </div>

      {/* Modal for showing the full description */}
      <SubCompetencyModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={modalContent.title}
        description={modalContent.description}
        score={modalContent.score}
      />
    </div>
  );
};

const subCompetencyDescriptions = {
  "Uncovers Needs": "Evaluates the salesperson's ability to detect and prioritize customer needs, uncover underlying factors, and understand customer pain points.",
  "Confirms Value": "Measures how effectively the salesperson communicates the value of the product or service, connecting it to the customer’s specific needs.",
  "Differentiates & Overcomes Objections": "Assesses the salesperson’s capability to distinguish the product or service from competitors and effectively handle objections.",
  "Probes for Insights": "Assesses the salesperson's ability to ask probing questions to gain deeper insights into the customer’s needs.",
  
  "Creates Urgency": "Assesses the salesperson's ability to communicate the necessity for change and create a sense of urgency to disrupt the status quo.",
  "Builds Business Case": "Evaluates the salesperson’s skill in developing a strong economic and strategic case for the customer, linking solution capabilities to purchase requirements.",
  "Executes Action Plan": "Measures how well the salesperson collaborates with the customer to create and execute a mutual action plan.",
  "Quantifies Benefits": "Measures the ability to provide quantifiable benefits and ROI calculations to support the business case.",
  
  "Demonstrates Expertise": "Measures the salesperson’s knowledge of the customer’s industry, business challenges, and the product or service.",
  "Tailors Solutions": "Assesses the ability to work with the customer to create customized solutions based on their unique needs.",
  "Confirms Solutions": "Evaluates how well the salesperson validates that the solution addresses the customer’s business needs.",
  "Understands Competitors": "Assesses the salesperson’s knowledge of competitor products and services.",
  "Differentiates Solutions": "Evaluates how well the salesperson can differentiate their product or service from competitors.",
  "Utilizes Competitive Intel": "Assesses the ability to leverage competitive intelligence in sales strategies and discussions.",
  
  "Storytelling Skills": "Measures the ability to engage the customer with compelling stories and presentations that convey a cohesive storyline.",
  "Builds Rapport": "Assesses the salesperson’s ability to build trust, credibility, and rapport with customers.",
  "Empathetic Dialogue": "Evaluates how well the salesperson listens to the customer, making them feel heard, and encourages a dialogue with engaging questions.",
  "Active Listening": "Evaluates the ability to actively listen to the customer, capturing both verbal and non-verbal cues.",
  "Adapts Communication Style": "Assesses how well the salesperson adapts their communication style to suit the customer’s preferences and needs.",
  
  "Understands Sales Stages": "Assesses the salesperson's comprehension of the sales stages followed by the organization.",
  "Understands Buying Process": "Evaluates the understanding of the customer’s buying journey, including stakeholder involvement and decision-making processes.",
  "Confirms Timeline": "Measures the ability to anticipate and confirm the customer’s purchase timeline accurately.",
  
  "Maintains Customer Relationships": "Evaluates the salesperson’s ability to maintain ongoing relationships with customers, ensuring continued engagement.",
  "Identifies Key Stakeholders": "Assesses the ability to identify and engage key decision-makers and influencers within the customer’s organization.",
  "Fosters Long-term Partnerships": "Measures how well the salesperson fosters long-term partnerships and builds loyalty with customers.",
  "Handles Customer Concerns": "Evaluates the ability to effectively handle and resolve customer concerns to maintain positive relationships."
};


export default BarChart;
