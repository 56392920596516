import React from 'react';

const RolePlayCards = ({ rolePlays }) => {
  if (!rolePlays || rolePlays.length === 0) {
    return (
      <div className="p-6 text-center text-gray-700">
        No role play recommendations for now.
      </div>
    );
  }

  return (
    <div className="mt-8">
      <h2 className="text-lg font-semibold leading-6 text-gray-900 mb-4">
        Recommended Role Plays
      </h2>
      {/* Flexbox container that wraps when necessary */}
      <div className="flex flex-wrap -mx-4">
        {rolePlays.map((rolePlay, index) => (
          <div
            key={index}
            className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-8"
          >
            {/* Individual card */}
            <div className="flex flex-col justify-between p-4 h-64 bg-white border border-gray-300 rounded-md shadow-lg">
              <div>
                <h3 className="text-md font-semibold text-gray-800">
                  {rolePlay.title}
                </h3>
                <p className="text-sm text-gray-600 mt-2">
                  Objective: {rolePlay.objective}
                </p>
              </div>
              <button className="mt-4 bg-pareBlue text-white font-semibold py-2 px-4 rounded hover:bg-indigo-500">
                Begin Role Play
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RolePlayCards;
