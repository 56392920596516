import React, { useState } from "react"
import { Avatar, AvatarFallback, AvatarImage } from "../../../@shadcn_components/ui/avatar"
import PareLogo from "./MessageIcons/PareLogo_new.png"
import { observer } from "mobx-react-lite"
import { useStores } from "../../../models/root-store"

import "./Message.css"

const Message = ({ message: { text, user, type }, uploadContextFile, uploadRubricFile, loading, messagesType }) => {
  let isSentByUser = false
  const { userStore } = useStores()
  const { userId, userEmail, managerId, name, company } = userStore.user || {}
  const [rubricUploaded, setRubricUploaded] = useState(false)
  const [personaUploaded, setPersonaUploaded] = useState(false)

  const handleRubricUpload = () => {
    if (rubricUploaded) {
      alert("File has already been uploaded")
    } else {
      setRubricUploaded(true)
      uploadRubricFile()
    }
  }
  const handleContextUpload = () => {
    if (personaUploaded) {
      alert("File has already been uploaded")
    } else {
      setPersonaUploaded(true)
      uploadContextFile()
    }
  }
  if (user === "user") {
    isSentByUser = true
  }
  const initials = name.split(' ')[0].toUpperCase().charAt(0) + name.split(' ')[1].toUpperCase().charAt(0)

  return isSentByUser ? (
    <div className="messageContainer justifyEnd">
      <div className="messageBoxUser">
        <p className="messageTextUser">{text}</p>
      </div>
      {messagesType ? (
        <div className="sentText pl-10">
          {" "}
          <Avatar>
            {/* <AvatarImage src={sessionStorage.getItem('ProfilePicture')} /> */}
            <AvatarFallback>{initials}</AvatarFallback>
          </Avatar>
        </div>
      ) : null}
    </div>
  ) : (
      <div className="messageContainer justifyStart">
        {messagesType ? (
          <div className="sentText pr-10 ">
            <Avatar>
              <AvatarImage src={PareLogo} />
              <AvatarFallback>{"Pare"}</AvatarFallback>
            </Avatar>
          </div>
        ) : null}
        <div className="messageBoxBot">
          <p className="messageTextBot">{text}</p>
          {type === "contextFileUploadButton" ? (
            <div>
              <button className="fileConfirmationButton" onClick={handleContextUpload}>
                {personaUploaded ? "File Uploaded!" : "Confirm Upload"}
              </button>
            </div>
          ) : type === "rubricFileUploadButton" ? (
            <div>
              <button className="fileConfirmationButton" onClick={handleRubricUpload}>
                {rubricUploaded ? "File Uploaded!" : "Confirm Upload"}
              </button>
            </div>
          ) : null}
        </div>
      </div>
    )
}

export default Message
