import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Auth, API } from "aws-amplify";
import Alert from "../../tailwinds_components/components/alert";
import Input from "../../tailwinds_components/components/input";
import logo from "../../tailwinds_components/images/logos/parelogo.svg";

export default function SignUp() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [inputAccessToken, setInputAccessToken] = useState("");
  const [alert, setAlert] = useState(null); // State for managing alerts
  const [confirmationCode, setConfirmationCode] = useState("");
  const [isConfirming, setIsConfirming] = useState(false); // Track if user is confirming their account
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)

  const handleSignUpRequest = async (event) => {
    event.preventDefault();
    const errors = [];
    setLoading(true)

    // Input validation
    if (!email.trim().match(/^.+@.+\.[a-z]+$/)) {
      errors.push("Please provide a valid email address.");
    }
    if (password !== confirmedPassword) {
      errors.push("Passwords do not match.");
    }
    if (password.length < 8) {
      errors.push("Password must be at least 8 characters.");
    }
    if (firstName.trim().length === 0) {
      errors.push("First name is required.");
    }
    if (lastName.trim().length === 0) {
      errors.push("Last name is required.");
    }

    if (errors.length > 0) {
      // Show error alert
      setAlert({ type: "error", title: "There were errors with your submission", messages: errors });
      return;
    }

    try {
      // Validate the access token before proceeding with sign up
      const response = await API.get("ZenoApp", "/validateAccessToken", {
        queryStringParameters: {
          email: email.toLowerCase(),
          accessToken: inputAccessToken,
        },
      });

      // Check if the access token is valid based on the API response
      if (!response.isValid) {
        let errorMessage = "The access token you entered is invalid.";
        if (response.error === "Access token mismatch") {
          errorMessage = "The access token you entered is incorrect.";
        } else if (response.error === "Access token expired") {
          errorMessage = "The access token you entered has expired.";
        } else if (response.error === "Access token already used") {
          errorMessage = "The access token you entered has already been used.";
        } else if (response.error === "User not found") {
          errorMessage = "No user found with the provided email.";
        }

        setAlert({
          type: "error",
          title: "Access token validation failed",
          messages: [errorMessage],
        });
        return; // Stop the process if the access token is invalid
      }

      // Step 1: Cognito user sign-up if access token is valid
      await Auth.signUp({
        username: email.toLowerCase(),
        password: password,
        attributes: {
          email: email.toLowerCase(),
          given_name: firstName,
          family_name: lastName,
        },
      });

      // Show success and prompt for confirmation
      setAlert({
        type: "success",
        title: "Account created!",
        messages: ["Please enter the confirmation code sent to your email."],
      });
      setIsConfirming(true);
    } catch (error) {
      // Show API errors
      setAlert({ type: "error", title: "Error during sign up", messages: [error.message] });
    } finally {
      setLoading(false)
    }
  };

  const handleSignUpConfirmation = async (event) => {
    event.preventDefault();
    try {
      setLoading(true)
      // Step 1: Confirm the user's sign-up
      await Auth.confirmSignUp(email.toLowerCase(), confirmationCode);

      // Step 2: Sign the user in to get the authentication token
      const user = await Auth.signIn(email.toLowerCase(), password);
      const session = await Auth.currentSession();
      const token = session.getAccessToken().getJwtToken();

      // Step 3: Fetch the invite details (managerId, invitedUserType, company) using the token
      const inviteDetails = await API.get("ZenoApp", "/getInviteDetails", {
        headers: { Authorization: `Bearer ${token}` },
        queryStringParameters: { email: email.toLowerCase() },
      });

      // Step 4: Call the /checkApproval route after fetching invite details
      const approval = await API.get("ZenoApp", "/checkApproval", {
        headers: { Authorization: `Bearer ${token}` },
        queryStringParameters: {
          email: email.toLowerCase(),
          name: `${firstName} ${lastName}`,
          company: inviteDetails.company, // Use the company retrieved from invite details
          flow: "signup",
          type: inviteDetails.invitedUserType, // Use the invitedUserType from invite details
          userId: user.attributes.sub,
          managerId: inviteDetails.managerId, // Use managerId from invite details
          managerName: inviteDetails.managerName
        },
      });

      if (approval.status) {
        setAlert({ type: "success", title: "Account confirmed and access token validated! You can now sign in.", messages: [] });
        navigate("/signin");
      } else {
        setAlert({ type: "error", title: "Access token validation failed.", messages: ["Your access token is invalid or expired."] });
      }
    } catch (error) {
      setAlert({ type: "error", title: "Error during confirmation or access token validation", messages: [error.message] });
    } finally {
      setLoading(false)
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img alt="Pare" src={logo} className="mx-auto h-10 w-auto" />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Sign Up</h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm space-y-6">
        {/* Display alert if any */}
        {alert && <Alert type={alert.type} title={alert.title} messages={alert.messages} />}

        {!isConfirming ? (
          <form onSubmit={handleSignUpRequest} className="space-y-6">
            <Input
              id="email"
              label="Email"
              name="email"
              type="email"
              placeholder="you@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              hasError={!!alert && alert.messages.includes("Please provide a valid email address.")}
              errorMessage="Please provide a valid email address."
            />

            <Input
              id="password"
              label="Password"
              name="password"
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              hasError={!!alert && alert.messages.includes("Password must be at least 8 characters.")}
              errorMessage="Password must be at least 8 characters."
            />

            <Input
              id="confirmPassword"
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              placeholder="Confirm your password"
              value={confirmedPassword}
              onChange={(e) => setConfirmedPassword(e.target.value)}
              hasError={!!alert && alert.messages.includes("Passwords do not match.")}
              errorMessage="Passwords do not match."
            />

            <Input
              id="firstName"
              label="First Name"
              name="firstName"
              type="text"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              hasError={!!alert && alert.messages.includes("First name is required.")}
              errorMessage="First name is required."
            />

            <Input
              id="lastName"
              label="Last Name"
              name="lastName"
              type="text"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              hasError={!!alert && alert.messages.includes("Last name is required.")}
              errorMessage="Last name is required."
            />

            {/* Access Token Input Field */}
            <Input
              id="accessToken"
              label="Access Token"
              name="accessToken"
              type="text"
              placeholder="Enter your access token"
              value={inputAccessToken}
              onChange={(e) => setInputAccessToken(e.target.value)}
              hasError={!!alert && alert.messages.includes("The access token you entered is incorrect.")}
              errorMessage="The access token you entered is incorrect."
            />

            <button type="submit" className="w-full py-2 px-4 bg-indigo-600 text-white rounded-md hover:bg-indigo-500">
              {loading ? 'Creating Account...' : 'Create Account'}
            </button>
            <p className="text-center text-sm">
              Already have an account?{" "}
              <Link to="/signin" className="text-indigo-600 hover:underline">Sign in!</Link>
            </p>
          </form>
        ) : (
            <form onSubmit={handleSignUpConfirmation} className="space-y-6">
              <Input
                id="confirmationCode"
                label="Confirmation Code"
                name="confirmationCode"
                type="text"
                placeholder="Enter confirmation code"
                value={confirmationCode}
                onChange={(e) => setConfirmationCode(e.target.value)}
              />

              <button type="submit" className="w-full py-2 px-4 bg-indigo-600 text-white rounded-md hover:bg-indigo-500">
                
                {loading ? 'Confirming Account...' : 'Confirm Account'}
            </button>
              <p className="text-center text-sm">
                Didn't get the code?{" "}
                <button type="button" onClick={() => Auth.resendSignUp(email)} className="text-indigo-600 hover:underline">
                  Resend!
              </button>
              </p>
            </form>
          )}
      </div>
    </div>
  );
}
