import React from "react"
import { ReactMultiEmail, isEmail } from "react-multi-email"



const InviteUserPopUp = ({ header, body, setEmails, setFocused, emails, leftButtonLabel, rightButtonLabel, leftButtonFunction, rightButtonFunction, handleTypeChange, userType }) => {


  return (<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">

    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

        <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
          <div>
            <div class="mt-3 text-center sm:mt-5">
              <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">{header}</h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500 p-6">{body}</p>
                <form>
                  <ReactMultiEmail
                    placeholder="Input email address"
                    emails={emails}
                    onChange={_emails => {
                      setEmails(_emails)
                    }}
                    autoFocus={true}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    getLabel={(email, index, removeEmail) => {
                      return (
                        <div data-tag key={index}>
                          <div data-tag-item>{email}</div>
                          <span data-tag-handle onClick={() => removeEmail(index)}>
                            ×
                      </span>
                        </div>
                      )
                    }}
                  />
                  <br />
                </form>
                <select
                  id="type"
                  name="type"
                  onChange={handleTypeChange}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                >
                  <option value="">Select role</option>
                  <option value="AE">AE</option>
                  <option value="SDR">SDR</option>
                  <option value="Manager">Manager</option>

                </select>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <button type="button" class="inline-flex w-full justify-center rounded-md bg-pareBlue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pareBlue sm:col-start-2" onClick={rightButtonFunction}>{rightButtonLabel}</button>
            <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0" onClick={leftButtonFunction}>{leftButtonLabel}</button>
          </div>
        </div>
      </div>
    </div>
  </div>)
}

export default InviteUserPopUp