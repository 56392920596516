import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Auth, API } from "aws-amplify"
import { useLocation } from "react-router-dom"
import { MultiSelect } from "react-multi-select-component"
import SingleOptionPopUp from "../../components/PopUps/SingleOptionPopUp"
import LoadingPopUp from "../../components/PopUps/LoadingPopUp"
import OneOffActivityBox from "../../components/OneOffActivityBox/OneOffActivityBox"
import ActivityTeamViewTableEntry from "../../components/ActivityTeamViewTableEntry/ActivityTeamViewTableEntry"
import "./ActivityTeamViewPage.css"
import { grid } from "ldrs"
import { Tabs, TabsList, TabsTrigger } from "../../@shadcn_components/ui/tabs"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../@shadcn_components/ui/breadcrumb"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"
import { toJS } from "mobx";
import ActivityTeamViewPageSkeleton from "../../components/ActivityTeamViewPage/skeletons"

grid.register()

let alottedTime = null // TODO: typo?

const ActivityTeamViewPage = () => {
  const { userStore, teamStore, moduleListStore, recordingListStore } = useStores()
  const navigate = useNavigate()
  const location = useLocation()
  const [teamMemberAnalysisEntryList, setTeamMemberAnalysisEntry] = useState([])
  const [AssignPopup, showAssignPopUp] = useState(false)
  const [reps, setReps] = useState([])
  const [dueDate, setDueDate] = useState("")
  const [popUpLoading, setPopUpLoading] = useState(false)
  const [repOptions, setRepOptions] = useState([])
  const [viewAttempts, setViewAttempts] = useState(false)
  const [attemptsArray, setAttemptsArray] = useState([])
  const [attemptsLoading, setAttemptsLoading] = useState(false)
  const [attemptedName, setAttemptedName] = useState("")
  const [currentTab, setCurrentTab] = useState("Overview")
  const [completionDist, setCompletionDist] = useState({})
  const [promptCreated, setPromptCreated] = useState(false)
  const [attemptData, setAttemptData] = useState({})
  const [moduleCatInfo, setModuleCatInfo] = useState({
    description: "",
    objective: "",
    persona_summary: "",
    scenario: "",
    rubric_name: "",
    links: "",
    duration: "",
    dueDate: "",
    numAttempts: "",
    testType: "",
  })
  const [pageLoading, setPageLoading] = useState(true)
  const [reminderSent, setReminderSent] = useState(false)
  const [showRubricPopUp, setRubricPopUp] = useState(false)
  const managerActivityData = moduleListStore.getModule(location.state.activity_id)
  const totalTeam = toJS(teamStore.teamInfo ?.team) || []

  const getAttempts = async (user_id, name, noAttemptsYet) => {
    if (noAttemptsYet.length > 0) {
      setAttemptedName(name)
      setViewAttempts(true)
      setAttemptsLoading(true)
      const attempts = recordingListStore.getAttempts(location.state.activity_id, user_id)

      const attemptCalcs = attempts.reduce((acc, x) => {
        if (x.passed) {
          acc.passed = true
        }
        if (x.createdAt > acc.lastAttemptDate) {
          acc.lastAttemptDate = x.createdAt
        }
        acc.sum += x.conversation_time
        acc.num += 1
        return acc
      }, { num: 0, sum: 0, passed: false, lastAttemptDate: 0 })
      setAttemptData(attemptCalcs)
      setAttemptsArray(attempts)
      setAttemptsLoading(false)
    }
  }

  const handlePopUpDueDateChange = e => {
    setDueDate(e.target.value)
  }

  const testModule = async () => {
    const moduleObj = location.state
    navigate("/chatRoomPage", { state: moduleObj })
  }

  const AssignToUser = async () => {
    if (reps.length > 0 && !overdueChecker(dueDate)) {
      setPopUpLoading(true)
      await API.post("ZenoApp", "/assignActivity", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        body: {
          manager_name: userStore.user.name,
          reps: reps,
          company: userStore.user.company,
          activity_id: location.state.activity_id,
          dueDate: dueDate,
        },
      })
      setReps([])
      setPopUpLoading(false)
      showAssignPopUp(false)
      moduleListStore.assignRep(location.state.activity_id, reps, dueDate)
    } else {
      alert("Please choose a rep to assign and a due date.")
    }
  }

  const handleDueDateChange = async (user_id, new_due_date) => {
    API.post("ZenoApp", "/changeDueDate", {
      headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      body: {
        activity_id: location.state.activity_id,
        user_id,
        new_due_date,
      },
    })
  }

  const handleAssignUserClick = () => {
    showAssignPopUp(true)
  }
  const handleBackButtonClick = () => {
    setReps([])
    showAssignPopUp(false)
  }

  useEffect(() => {
    const fetchTeam = async () => {
      if (!teamStore.teamInfo) {
        await teamStore.getTeam(userStore.user.userId, userStore.user.type, userStore.user.company)
      }
    }
    const fetchRecordings = async () => {
      if (!recordingListStore.userRecordings) {
        await recordingListStore.getModuleAttempts(location.state.activity_id, true)
      } else if (!recordingListStore.isAttemptFetched()) {
        await recordingListStore.getModuleAttempts(location.state.activity_id, false)
      }
    }

    const fetchModules = async () => {
      if (!moduleListStore.userModules) {
        if (userStore.user.type === 'SDR' || userStore.user.type === 'AE') {
          await moduleListStore.getAssignedModules(userStore.user.company)
        } else {
          await moduleListStore.getManagerModules(userStore.user.type, userStore.user.company)
        }
      }
    }

    const fetchData = async () => {
      try {

        await Promise.all([fetchTeam(), fetchModules(), fetchRecordings()]);

        // Continue with your logic after ensuring both stores are ready
        const participantsObj = managerActivityData.testParticipants
          ? JSON.parse(managerActivityData.testParticipants)
          : {};
        const participantKeys = Object.keys(participantsObj);
        setRepOptions(
          totalTeam.reduce((acc, x) => {
            if (!participantKeys.includes(x.userId) && x.type !== 'Manager' && x.type != 'Admin') {
              acc.push({
                label: x.name,
                value: x.userId,
              });
            }
            return acc;
          }, []),
        );




        setTeamMemberAnalysisEntry(
          participantKeys.map(x => {
            return Object.assign(participantsObj[x], { user_id: x });
          }),
        );

        setCompletionDist(
          participantKeys.reduce(
            (acc, x) => {
              if (participantsObj[x].status === "Incomplete") {
                if (overdueChecker(participantsObj[x].dueDate)) {
                  acc["Overdue"] += 1;
                } else {
                  acc["Not Started"] += 1;
                }
              } else if (participantsObj[x].status === "Passed") {
                acc["Passed"] += 1;
              } else if (participantsObj[x].status === "Not Passed" || participantsObj[x].status === "In Progress") {
                acc["Not Passed"] += 1;
              } else {
                acc["Pending"] += 1;
              }
              return acc;
            },
            { "Not Started": 0, Passed: 0, Pending: 0, "Not Passed": 0, Overdue: 0 },
          ),
        );

        setModuleCatInfo({
          description: managerActivityData.description,
          objective: managerActivityData.objective,
          persona_summary: managerActivityData.persona_summary,
          scenario: managerActivityData.scenario,
          rubric_name: managerActivityData.rubricFile,
          links: managerActivityData.links,
          duration: managerActivityData.timeLimit,
          testType: managerActivityData.testType,
          dueDate,
        });

        alottedTime = managerActivityData.timeLimit;
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        // Ensure page loading is set to false after both fetches or if already populated
        setPageLoading(false);
      }
    }

    fetchData()

  }, [pageLoading])

  const assignmentPopUp = AssignPopup ? (
    <div className={`frostedBackground is-visible`}>
      {popUpLoading ?
        <LoadingPopUp header="Assigning Learners..."></LoadingPopUp>
        : (
          <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">

            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

                <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div class="mt-3 text-center sm:mt-5">
                      <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">{"Add Learners to Module"}</h3>
                      <div class="mt-2 mb-4">
                        <p class="text-sm text-gray-500">{` Please select the additional reps within your team that you’d like to assign this to`}</p>
                      </div>
                      <div className="w-full mb-6">
                        <MultiSelect options={repOptions} value={reps} onChange={setReps} labelledBy="Select Reps"></MultiSelect>
                      </div>
                      <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">{"Due Date"}</h3>
                      <div class="mt-2 mb-4">
                        <p class="text-sm text-gray-500">{`Set the due date for new learners`}</p>
                      </div>

                      <input
                        type="date"
                        className="LearnersDueDateInput w-full mb-6"
                        value={dueDate}
                        onChange={handlePopUpDueDateChange}></input>
                    </div>
                  </div>
                  <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button type="button" class="inline-flex w-full justify-center rounded-md bg-pareBlue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pareBlue sm:col-start-2" onClick={AssignToUser}>{"Assign"}</button>
                    <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0" onClick={handleBackButtonClick}>{"Cancel"}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  ) : null

  const handleBackToTeamViewClick = () => {
    setViewAttempts(false)
  }

  const overdueChecker = dueDate => {
    const stringToDate = new Date(dueDate)
    const epochDueDate = stringToDate.getTime()
    const today = new Date().toDateString()
    const epochToday = new Date(today).getTime()
    return epochToday > epochDueDate
  }

  const sendReminder = async (e, name, email, testName, dueDate) => {
    e.stopPropagation()
    if (!reminderSent) {
      setReminderSent(true)
      await API.post("ZenoApp", "/sendReminder", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        body: {
          name,
          email,
          testName,
          dueDate,
        },
      })
    } else {
      alert("Reminder already sent.")
    }
  }

  const closeRubricPopUp = () => setRubricPopUp(false)
  const rubricPopUp = showRubricPopUp ? (
    <SingleOptionPopUp header="Custom Rubric" body={location.state.rubric_history} buttonLabel="Close" buttonFunction={closeRubricPopUp}></SingleOptionPopUp>
  ) : null

  return (
    <>
      {pageLoading ? <ActivityTeamViewPageSkeleton></ActivityTeamViewPageSkeleton> :
        <div class="w-full h-full flex flex-col px-8 md:px-20 pt-8">
          <>
            <div className="mb-8">
              {viewAttempts ? (
                <div>
                  <Breadcrumb>
                    <BreadcrumbList>
                      <BreadcrumbItem>
                        <BreadcrumbLink href="/managerDev">Modules</BreadcrumbLink>
                      </BreadcrumbItem>
                      <BreadcrumbSeparator />
                      <BreadcrumbItem>
                        <BreadcrumbPage>{`${attemptedName} Attempts`}</BreadcrumbPage>
                      </BreadcrumbItem>
                    </BreadcrumbList>
                  </Breadcrumb>
                  <div>
                    <header class='pt-4 pb-6'>

                      <div class="flex flex-col items-start justify-between gap-x-8 gap-y-4 bg-gray-400/10 px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8 rounded">
                        <div>
                          <div class="flex items-center gap-x-3">
                            <div class="flex-none rounded-full bg-yellow-400/10 p-1 text-yellow-400">
                              <div class="h-2 w-2 rounded-full bg-current"></div>
                            </div>

                            <h1 class="flex gap-x-3 text-base leading-7">

                              <span class="font-semibold text-grey">{location.state.testName}</span>
                            </h1>
                          </div>
                          <p class="mt-2 text-xs leading-6 text-gray-400">{`${location.state.testName} Attempts by ${attemptedName}`}</p>
                        </div>
                        <button class="order-first flex-none rounded bg-pareBlue px-2 py-1 text-s font-medium text-white ring-1 ring-inset ring-pareBlue sm:order-none" onClick={handleBackToTeamViewClick}>Back to team view</button>
                      </div>

                      <div class="grid grid-cols-1 bg-gray-400/10 sm:grid-cols-2 lg:grid-cols-4">
                        <div class="border-t border-white/5 px-4 py-6 sm:px-6 lg:px-8">

                          <p class="text-sm font-medium leading-6 text-gray-400 flex">
                            {/* <div class="flex-none rounded-full bg-yellow-400/10 p-1 text-yellow-400 flex items-center justify-center">
                            <div class="h-2 w-2 bg-grey-400 rounded-full bg-current"></div>
                          </div> */}
                            Passed
                        </p>
                          <p class="mt-2 flex items-baseline gap-x-2">
                            <span class="text-4xl font-semibold tracking-tight text-grey">{attemptData.passed ? "Yes" : "No"}</span>
                          </p>
                        </div>
                        <div class="border-t border-white/5 px-4 py-6 sm:border-l sm:px-6 lg:px-8">
                          <p class="text-sm font-medium leading-6 text-gray-400 flex">
                            {/* <div class="flex-none rounded-full bg-yellow-400/10 p-1 text-yellow-400 flex items-center justify-center">
                            <div class="h-2 w-2 bg-grey-400 rounded-full bg-current"></div>
                          </div> */}
                            Average Attempt Time</p>
                          <p class="mt-2 flex items-baseline gap-x-2">
                            <span class="text-4xl font-semibold tracking-tight text-grey">{attemptData.sum / attemptData.num}</span>
                            <span class="text-sm text-gray-400">secs</span>
                          </p>
                        </div>
                        <div class="border-t border-white/5 px-4 py-6 sm:px-6 lg:border-l lg:px-8">
                          <p class="text-sm font-medium leading-6 text-gray-400 flex">
                            {/* <div class="flex-none rounded-full bg-yellow-400/10 p-1 text-yellow-400 flex items-center justify-center">
                            <div class="h-2 w-2 bg-grey-400 rounded-full bg-current"></div>
                          </div> */}
                            Last Attempt Date</p>
                          <p class="mt-2 flex items-baseline gap-x-2">
                            <span class="text-4xl font-semibold tracking-tight text-grey">{new Date(attemptData.lastAttemptDate).toLocaleDateString()}</span>
                          </p>
                        </div>
                        <div class="border-t border-white/5 px-4 py-6 sm:border-l sm:px-6 lg:px-8">
                          <p class="text-sm font-medium leading-6 text-gray-400 flex">
                            {/* <div class="flex-none rounded-full bg-yellow-400/10 p-1 text-yellow-400 flex items-center justify-center">
                            <div class="h-2 w-2 bg-grey-400 rounded-full bg-current"></div>
                          </div> */}
                            Total Attempts
                          </p>
                          <p class="mt-2 flex items-baseline gap-x-2">
                            <span class="text-4xl font-semibold tracking-tight text-grey">{attemptData.num}</span>
                          </p>
                        </div>

                      </div>
                    </header>
                    {attemptsLoading ? (
                      <div className="flex-1 px-8 flex flex-row content-center justify-center">
                        <l-grid size="120" speed="1.4" color="#3045FF"></l-grid>
                      </div>
                    ) : (
                        <div className="OneOffActivityCarousel">
                          {attemptsArray.map((x, i) => (
                            <div className="OneOffActivityBoxContainer" key={i}>
                              <OneOffActivityBox
                                oneOffActivityInfo={x}
                                testName={managerActivityData.testName}
                              ></OneOffActivityBox>
                            </div>
                          ))}
                        </div>
                      )}
                  </div>
                </div>

              ) : (
                  <div>
                    <Breadcrumb>
                      <BreadcrumbList>
                        <BreadcrumbItem>
                          <BreadcrumbLink href="/managerDev">Modules</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                          <BreadcrumbPage>{`${location.state.testName}`}</BreadcrumbPage>
                        </BreadcrumbItem>
                      </BreadcrumbList>
                    </Breadcrumb>
                    <header class='pt-4 pb-6'>

                      <div class="flex flex-col items-start justify-between gap-x-8 gap-y-4 bg-gray-400/10 px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8 rounded">
                        <div>
                          <div class="flex items-center gap-x-3">
                            <div class="flex-none rounded-full bg-yellow-400/10 p-1 text-yellow-400">
                              <div class="h-2 w-2 rounded-full bg-current"></div>
                            </div>

                            <h1 class="flex gap-x-3 text-base leading-7">

                              <span class="font-semibold text-grey">{location.state.testName}</span>
                            </h1>
                          </div>
                          <p class="mt-2 text-xs leading-6 text-gray-400">{`${location.state.testName} Summary`}</p>
                        </div>
                        <button class="order-first flex-none rounded bg-pareBlue px-2 py-1 text-s font-medium text-white ring-1 ring-inset ring-pareBlue sm:order-none" onClick={testModule}>Test Module</button>
                      </div>

                      <div class="grid grid-cols-1 bg-gray-400/10 sm:grid-cols-2 lg:grid-cols-5">
                        <div class="border-t border-white/5 px-4 py-6 sm:px-6 lg:px-8">

                          <p class="text-sm font-medium leading-6 text-gray-400 flex">
                            <div class="flex-none rounded-full  p-1 text-yellow-400 flex items-center justify-center">
                              <div class="h-2 w-2 bg-green rounded-full bg-current"></div>
                            </div>
                            Passed
                        </p>
                          <p class="mt-2 flex items-baseline gap-x-2">
                            <span class="text-4xl font-semibold tracking-tight text-grey">{completionDist.Passed}</span>
                          </p>
                        </div>
                        <div class="border-t border-white/5 px-4 py-6 sm:border-l sm:px-6 lg:px-8">
                          <p class="text-sm font-medium leading-6 text-gray-400 flex">
                            <div class="flex-none rounded-full  p-1 text-yellow-400 flex items-center justify-center">
                              <div class="h-2 w-2 bg-yellow rounded-full bg-current"></div>
                            </div>
                            Pending</p>
                          <p class="mt-2 flex items-baseline gap-x-2">
                            <span class="text-4xl font-semibold tracking-tight text-grey">{completionDist.Pending}</span>
                          </p>
                        </div>
                        <div class="border-t border-white/5 px-4 py-6 sm:px-6 lg:border-l lg:px-8">
                          <p class="text-sm font-medium leading-6 text-gray-400 flex">
                            <div class="flex-none rounded-full  p-1 text-yellow-400 flex items-center justify-center">
                              <div class="h-2 w-2 bg-orange rounded-full bg-current"></div>
                            </div>In Progress</p>
                          <p class="mt-2 flex items-baseline gap-x-2">
                            <span class="text-4xl font-semibold tracking-tight text-grey">{completionDist['Not Passed']}</span>
                          </p>
                        </div>
                        <div class="border-t border-white/5 px-4 py-6 sm:border-l sm:px-6 lg:px-8">
                          <p class="text-sm font-medium leading-6 text-gray-400 flex">
                            <div class="flex-none rounded-full  p-1 text-yellow-400 flex items-center justify-center">
                              <div class="h-2 w-2 bg-red rounded-full bg-current"></div>
                            </div>Overdue</p>
                          <p class="mt-2 flex items-baseline gap-x-2">
                            <span class="text-4xl font-semibold tracking-tight text-grey">{completionDist.Overdue}</span>
                          </p>
                        </div>
                        <div class="border-t border-white/5 px-4 py-6 sm:border-l sm:px-6 lg:px-8">
                          <p class="text-sm font-medium leading-6 text-gray-400 flex">
                            <div class="flex-none rounded-full  p-1 text-yellow-400 flex items-center justify-center">
                              <div class="h-2 w-2 rounded-full bg-current"></div>
                            </div>Not Started</p>
                          <p class="mt-2 flex items-baseline gap-x-2">
                            <span class="text-4xl font-semibold tracking-tight text-grey">{completionDist["Not Started"]}</span>
                          </p>
                        </div>
                      </div>
                    </header>
                    <div class="flex flex-col items-start justify-between py-2 sm:flex-row sm:items-center rounded">
                      <div>
                        <Tabs
                          defaultValue={currentTab}
                          className="w-[100%]"
                          onValueChange={str => {
                            setCurrentTab(str)
                            setViewAttempts(false)
                          }}>
                          <TabsList>
                            <TabsTrigger value="Overview">Overview</TabsTrigger>
                            <TabsTrigger value="Assigned Learners">Assigned Learners</TabsTrigger>
                          </TabsList>
                        </Tabs>
                      </div>
                      {userStore.user.userId === location.state.manager_id ? <button class="order-first flex-none rounded-full bg-indigo-400/10 px-2 py-1 text-s font-medium text-pareBlue ring-1 ring-inset ring-pareBlue sm:order-none" onClick={handleAssignUserClick}>Assign Learners</button> : null}
                    </div>
                    {/* <div className="w-full pb-8">
                    <Tabs
                      defaultValue={currentTab}
                      className="w-[100%]"
                      onValueChange={str => {
                        setCurrentTab(str)
                        setViewAttempts(false)
                      }}>
                      <TabsList>
                        <TabsTrigger value="Overview">Overview</TabsTrigger>
                        <TabsTrigger value="Assigned Learners">Assigned Learners</TabsTrigger>
                      </TabsList>
                    </Tabs>
                  </div> */}
                    {currentTab === 'Overview' ?
                      <div class="mt-6 border-t border-gray-100">
                        <dl class="divide-y divide-gray-100">
                          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Description</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 whitespace-pre-line">{moduleCatInfo.description}</dd>
                          </div>
                          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Objective</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 whitespace-pre-line">{moduleCatInfo.objective}</dd>
                          </div>
                          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Call Type</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 whitespace-pre-line">{moduleCatInfo.testType}</dd>
                          </div>
                          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Persona Summary</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 whitespace-pre-line">{moduleCatInfo.persona_summary}</dd>
                          </div>
                          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Scenario Background</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 whitespace-pre-line">{moduleCatInfo.scenario}</dd>
                          </div>
                          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Links</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 whitespace-pre-line">{moduleCatInfo.links === "" ? "N/A" : moduleCatInfo.links ?.split(",").map(link => (
                              <button
                                key={`link_button_${link}`}
                                onClick={() => {
                                  const url = link.startsWith("http://") || link.startsWith("https://") ? link : `https://${link}`;
                                  window.open(url, "_blank")
                                }
                                }
                                className="rounded-md shadow-md p-2 hover:bg-gray-200">
                                <p className="line-clamp-2 font-semibold text-md text-left">{link}</p>
                              </button>
                            ))}</dd>
                          </div>
                          <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Rubric</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 whitespace-pre-line">
                              <button
                                onClick={() => setRubricPopUp(true)}
                                className="rounded-md shadow-md p-2 hover:bg-gray-200">
                                <p className="line-clamp-2 font-semibold text-md text-left">{'Custom Rubric'}</p>
                              </button>
                            </dd>
                          </div>

                        </dl>
                      </div> : <div class="mt-2 flow-root">
                        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table class="min-w-full divide-y divide-gray-300">
                              <thead>
                                <tr>
                                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Name</th>
                                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Due Date</th>
                                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Attempts</th>

                                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                    <span class="sr-only">Edit</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody class="divide-y divide-gray-200 bg-white">
                                {teamMemberAnalysisEntryList.map((teamMember, i) => (
                                  <ActivityTeamViewTableEntry teamMember={teamMember} getAttempts={getAttempts} overdueChecker={overdueChecker} handleDueDateChange={handleDueDateChange} sendReminder={sendReminder} testName={managerActivityData.testName} dueDate={managerActivityData.dueDate}></ActivityTeamViewTableEntry>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>}
                  </div>

                )}

            </div>



          </>
          {rubricPopUp}
          {assignmentPopUp}
        </div>}</>
  )
}

export default observer(ActivityTeamViewPage)
