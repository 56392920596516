import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Auth, API } from "aws-amplify"
import ActivityBox from "../../components/ActivityBox/ActivityBox"
import WarningPopUp from "../../components/PopUps/WarningPopUp"
import LoadingPopUp from "../../components/PopUps/LoadingPopUp"
import SingleInputPopUp from "../../components/PopUps/SingleInputPopUp"
import { grid } from "ldrs"
import "./ManagerDevelopmentPage.css"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../@shadcn_components/ui/breadcrumb"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"
import { toJS } from "mobx";
import ManagerDevPageSkeleton from "../../components/ManagerDevPage/skeletons"

const refreshIcon = require("./ManagerDevelopmentPageIcons/refresh.png")



grid.register()

const ManagerDevelopmentPage = () => {
  const { moduleListStore, userStore } = useStores()
  const activityList = moduleListStore.userModules ?.moduleList ? toJS(moduleListStore.userModules.moduleList) : []
  const navigate = useNavigate()
  const [pageLoading, setPageLoading] = useState(true)
  const [searchBarInput, setSearchBarInput] = useState("")
  const [deletePopUpMenu, setDeletePopUpMenu] = useState(false)
  const [renamePopUpMenu, setRenamePopUpMenu] = useState(false)
  const [renameInput, setRenameInput] = useState("")
  const [loading, setLoading] = useState(false)
  const [popUpMenuDetails, setPopUpMenuDetails] = useState(null)
  const activityNameList = useState(activityList.map(x => x.testName.toLowerCase()))

  const refresh = async () => {
    setPageLoading(true)
    if (userStore.user.type === 'SDR' || userStore.user.type === 'AE') {
      await moduleListStore.getAssignedModules(userStore.user.company)
    } else {
      await moduleListStore.getManagerModules(userStore.user.type, userStore.user.company)
    }
    setPageLoading(false)
  }


  useEffect(() => {
    const fetchModules = async () => {
      if (!moduleListStore.userModules) {

        if (userStore.user.type === 'SDR' || userStore.user.type === 'AE') {
          await moduleListStore.getAssignedModules(userStore.user.company)
        } else {
          await moduleListStore.getManagerModules(userStore.user.type, userStore.user.company)
        }
      }
      setPageLoading(false)
    }

    fetchModules()
  }, [])

  const handleSearchBarChange = e => {
    e.preventDefault()
    setSearchBarInput(e.target.value)
  }

  const openMenuPopUp = convoObj => {
    setPopUpMenuDetails(convoObj)
    if (convoObj.popUpType === "delete") {
      setDeletePopUpMenu(true)
    } else if (convoObj.popUpType === "rename") {
      setRenamePopUpMenu(true)
    }
  }
  const closeMenuPopUp = () => {
    if (popUpMenuDetails.popUpType === "delete") {
      setDeletePopUpMenu(false)
    } else if (popUpMenuDetails.popUpType === "rename") {
      setRenamePopUpMenu(false)
    }
    setPopUpMenuDetails(null)
  }

  const handleRenameInputChange = event => {
    setRenameInput(event.target.value)
  }

  const renameActivity = async () => {
    if (renameInput.trim() === "" || renameInput.trim() > 25) {
      alert("Rename the conversation to a valid name")
    } else if (activityNameList.includes(renameInput.toLowerCase().trim())) {
      alert("Conversation Name already exists")
    } else {
      setLoading(true)
      await API.post("ZenoApp", "/renameActivity", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        body: {
          activity_id: popUpMenuDetails.activity_id,
          testName: renameInput,
        },
      })
      moduleListStore.renameModule(popUpMenuDetails.activity_id, renameInput)
      setLoading(false)
      closeMenuPopUp()
    }
  }
  const deleteActivity = async () => {
    setLoading(true)
    await API.post("ZenoApp", "/deleteActivity", {
      headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      body: {
        activity_id: popUpMenuDetails.activity_id,
      },
    })
    moduleListStore.removeModule(popUpMenuDetails.activity_id)
    setLoading(false)
    closeMenuPopUp()
  }

  const handleCreateNewModuleClick = () => {
    navigate("/activityCreationPage")
  }

  const RenamePopUpMenuDiv = renamePopUpMenu ? (
    <div className={`frostedBackground is-visible`}>
      {loading ? (
        <LoadingPopUp header="Renaming Module..."></LoadingPopUp>
      ) : (
          <SingleInputPopUp
            header={`Rename "${popUpMenuDetails.testName}"`}
            body={`Enter new assignment title to change name`}
            leftButtonFunction={closeMenuPopUp}
            leftButtonLabel="Cancel"
            rightButtonFunction={renameActivity}
            rightButtonLabel="Rename"
            handleInputChange={handleRenameInputChange}
          ></SingleInputPopUp>
        )}
    </div>
  ) : null
  const DeletePopUpMenuDiv = deletePopUpMenu ? (
    <div className={`frostedBackground is-visible`}>
      {loading ? (
        <LoadingPopUp header="Deleting Module..."></LoadingPopUp>
      ) : (
          <WarningPopUp header={`Delete "${popUpMenuDetails.testName}" ?`} body={`Are you sure you want to delete this recording?`} leftButtonLabel="Cancel" leftButtonFunction={closeMenuPopUp} rightButtonFunction={deleteActivity} rightButtonLabel="Delete Recording" ></WarningPopUp>
        )}
    </div>
  ) : null

  return (
    <>
      {pageLoading ? <ManagerDevPageSkeleton></ManagerDevPageSkeleton> :
        <div class="w-full h-full flex flex-col px-8 md:px-20 pt-8">
          <div className="mb-8">
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem>Modules</BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
          </div>
          <div class="sm:flex sm:items-center">
            <div class="sm:flex-auto">
              <h1 class="text-base font-semibold leading-6 text-gray-900">Module Library</h1>
              <p class="mt-2 text-sm text-gray-700">View, edit, and create role play modules</p>
            </div>
            <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <button type="button" class="block rounded-md bg-pareBlue px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" onClick={handleCreateNewModuleClick}>Create Module</button>
            </div>
          </div>
          <div class="content-center align-items flex gap-5">
            <input
              type="text"
              placeholder="Search"
              className="mb-8 mt-4 rounded border-grey-200 w-[500px]"
              value={searchBarInput}
              onChange={handleSearchBarChange}></input>
            <button class="w-7 h-7 bg-coverborder-none cursor-pointer hover:opacity-80 mb-7 mt-6" onClick={refresh}>
              <img src={refreshIcon}></img>
            </button>
          </div>
          {activityList.length == 0 ? (
            <div className="w-full h-[40%] flex flex-col items-center justify-center">
              <label
                class="text-grey-200 mb-2">
                No Existing Modules
            </label>
              <label
                class="text-grey-200">
                Build, design, and publish a module for your team when you’re ready
            </label>
            </div>
          ) : (
              <div className="w-full h-full rounded-md shadow-sm flex flex-row flex-wrap">
                {searchBarInput.length > 0
                  ? activityList
                    .filter(x => x.testName.match(new RegExp(`^${searchBarInput}.*`, "i")))
                    .map((x, i) => (
                      <div key={i}>
                        <ActivityBox type={"FlexBox"} activityInfo={x} handleMenuPopUp={openMenuPopUp}></ActivityBox>
                      </div>
                    ))
                  : activityList.map((x, i) => (
                    <div key={i}>
                      <ActivityBox type={"FlexBox"} activityInfo={x} handleMenuPopUp={openMenuPopUp}></ActivityBox>
                    </div>
                  ))}
              </div>
            )}
          {RenamePopUpMenuDiv}
          {DeletePopUpMenuDiv}
        </div>}
    </>
  )
}

export default observer(ManagerDevelopmentPage)
