import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';

// Top Section Skeleton (5% of viewport)
export const BreadcrumbSkeleton = () => (
  <div className="mb-2">
    <Skeleton height={40} width={200} />
  </div>
);

// Middle Section Skeleton (15% of viewport)
export const ManagerDevPageHeaderSkeleton = () => (
  <div className="w-full">
    <Skeleton height="15vh" />
  </div>
);

// Bottom Section Skeleton (75% of viewport)
export const ModuleListSkeleton = () => (
  <div className="w-full">
    <Skeleton height="60vh" />
  </div>
);

export const ManagerDevPageSkeleton = () => (
  <div className="w-full h-screen p-8 flex flex-col gap-4">
    {/* Top Section Skeleton (5% of viewport) */}
    <BreadcrumbSkeleton />

    {/* Middle Section Skeleton (15% of viewport) */}
    <ManagerDevPageHeaderSkeleton />

    {/* Bottom Section Skeleton (75% of viewport) */}
    <ModuleListSkeleton />
  </div>
);

export default ManagerDevPageSkeleton;

