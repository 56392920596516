import React from "react"

import ScrollToBottom from "react-scroll-to-bottom"

import Message from "./Message/Message"

import "./Messages.css"

const Messages = ({ messages, type, loading, uploadContextFile, uploadRubricFile, messagesType }) => {
  return (
    <ScrollToBottom className="w-full h-full pt-8 overflow-auto flex pb-4">
      {messages.map((message, i) => (
        <div key={`${message.user}-${message.text.length}-${i}`}>
          <Message
            message={message}
            type={type}
            uploadContextFile={uploadContextFile}
            uploadRubricFile={uploadRubricFile}
            loading={loading}
            messagesType={messagesType}
          />
        </div>
      ))}
    </ScrollToBottom>
  )
}

export default Messages
