import { types, Instance, flow, SnapshotIn } from "mobx-state-tree"
import { getAssignedModulesInfo, getManagerModulesInfo } from "../../api/routes"

const ModuleFieldsModel = types
  .model("ModuleFields", {
    activity_id: types.string,
    manager_id: types.maybeNull(types.string),
    created_date: types.maybeNull(types.number),
    company: types.string,
    deleted: types.string,
    doneCreation: types.string,
    grading_assistant: types.maybeNull(types.string),
    links: types.maybeNull(types.string),
    contextFile: types.maybeNull(types.string),
    persona_display_name: types.maybeNull(types.string),
    context_id: types.maybeNull(types.string),
    persona_summary: types.maybeNull(types.string),
    roleplay_history: types.maybeNull(types.string),
    rubricFile: types.maybeNull(types.string),
    rubric_history: types.maybeNull(types.string),
    rubric_id: types.maybeNull(types.string),
    contextSummary: types.maybeNull(types.string),
    testDecription: types.string,
    testName: types.string,
    testObj: types.string,
    testType: types.maybeNull(types.string),
    timeLimit: types.string,
    voice: types.string,
    presentation: types.boolean,
    testParticipants: types.maybeNull(types.string),
    roleplay_assistant_id: types.maybeNull(types.string),
  });


const ModuleListFieldsModel = types
  .model("ModulesFields", {
    moduleList: types.array(ModuleFieldsModel)
  })

export type ModuleListFieldsSnapshotIn = SnapshotIn<typeof ModuleListFieldsModel>

export const ModuleListStoreModel = types
  .model("ModulesStore", {
    userModules: types.maybeNull(ModuleListFieldsModel),
  })
  .actions(self => ({
    getAssignedModules: flow(function* getAssignedModules(company) {
      try {
        const moduleInfoRes = yield getAssignedModulesInfo(company)
        console.log(moduleInfoRes.data)
        if (moduleInfoRes.kind === "ok") {
          self.userModules = ModuleListFieldsModel.create({
            moduleList: moduleInfoRes.data.map(mod => ModuleFieldsModel.create(mod))
          })
          return true
        }
      } catch (e) {
        return false
      }
      return false
    })
  }))
  .actions(self => ({
    getManagerModules: flow(function* getManagerModules(userType, company) {
      try {
        const managerModuleInfoRes = yield getManagerModulesInfo(userType, company)
        console.log(managerModuleInfoRes.data)
        if (managerModuleInfoRes.kind === "ok") {
          self.userModules = ModuleListFieldsModel.create({
            moduleList: managerModuleInfoRes.data.map(mod => ModuleFieldsModel.create(mod))
          })
          return true
        }
      } catch (e) {
        return false
      }
      return false
    })
  }))
  .actions(self => ({
    // Add a new sales rep to the team
    addModule(module) {
      const addModule = ModuleFieldsModel.create(module)
      self.userModules.moduleList.push(addModule)
    },

    // Remove a sales rep from the team by id
    removeModule(actId) {
      const index = self.userModules.moduleList.findIndex(rep => rep.activity_id === actId)
      if (index > -1) {
        self.userModules.moduleList.splice(index, 1)
      }
    },
    // Rename module to newName
    renameModule(actId, newName) {
      const index = self.userModules.moduleList.findIndex(mod => mod.activity_id === actId);
      if (index > -1) {
        self.userModules.moduleList[index].testName = newName
      }
    },
    getModuleUserSpecific(actId, userId) {
      if (!self.userModules) return {}
      const module = self.userModules.moduleList.find(mod => mod.activity_id === actId) || null

      if (module) {

        return {
          description: module.testDecription,
          objective: module.testObj,
          persona_summary: module.persona_summary,
          duration: module.timeLimit,
          links: module.links,
          rubric_name: module.rubricFile,
          testType: module.testType,
          scenario: module.contextSummary,
          dueDate: JSON.parse(module.testParticipants)[userId].dueDate,
          userStatus: JSON.parse(module.testParticipants)[userId].status,
        }
      }

      return null
    },
    getModule(actId) {
      if (!self.userModules) return {}
      const module = self.userModules.moduleList.find(mod => mod.activity_id === actId) || null

      if (module) {

        return {
          description: module.testDecription,
          objective: module.testObj,
          persona_summary: module.persona_summary,
          duration: module.timeLimit,
          links: module.links,
          rubric_name: module.rubricFile,
          scenario: module.contextSummary,
          testParticipants: module.testParticipants,
          testType: module.testType
        }
      }

      return null
    },
    getAllRubrics() {
      if (!self.userModules) return []
      return self.userModules.moduleList.map(x => {
        return { rubric_id: x.rubric_id, displayName: x.rubricFile }
      })
    },
    getAllPersonas() {
      if (!self.userModules) return []
      return self.userModules.moduleList.map(x => {
        return { context_id: x.context_id, displayName: x.persona_display_name }
      })
    },
    assignRep(activityId: string, reps: Array<any>, dueDate: string) {
      if (!self.userModules) return false
      const index = self.userModules.moduleList.findIndex(mod => mod.activity_id === activityId)
      const selectedModule = self.userModules.moduleList.find(mod => mod.activity_id == activityId)
      const testParticipants = selectedModule.testParticipants ? JSON.parse(selectedModule.testParticipants) : {}
      const assignedReps = reps.reduce((acc, x) => {
        acc[x.value] = { name: x.label, email: 'email', color: 'Yellow', status: 'Incomplete', duration: [], score: [], ai_score: [], conversation_id: [], dueDate }
        return acc
      }, {})
      Object.assign(testParticipants, assignedReps)
      self.userModules.moduleList[index].testParticipants = JSON.stringify(testParticipants)

      return true
    }
  }))

export type ModuleListStore = Instance<typeof ModuleListStoreModel>
